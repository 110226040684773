import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

import { ChatsLayout, InputBar } from './components';
import { Loader } from '../../components';
import { getAllChats, getAllUsers } from '../../services';
import { AuthUserType, ChatsListType, MessageType, ListChatType } from '../../types';
import { Container, MessageContainer } from './styles-chat';
import { chPerm } from '../../helpers';
import { DataContext } from '../../contexts';

const connectionString = process.env.REACT_APP_SOCKET_ENV || 'http://localhost:5000';
const socket = io(connectionString, {
  path: '/sockets',
});

export const Chat = () => {
  const [rooms, setRooms] = useState<ListChatType>({} as ListChatType);
  const [messagesRoom, setMessagesRoom] = useState<ChatsListType>({} as ChatsListType);
  const [users, setUsers] = useState<AuthUserType[]>([]);
  const [currentRoom, setCurrentRoom] = useState({ id: '0', name: 'NO_ROOM' });
  const [loading, setLoading] = useState(false);
  const { user } = useContext(DataContext);
  const { t } = useTranslation();

  const nameWriter = user?.id?.toString();

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleAddMessageByRoom = useCallback(
    ({ message, room, recipientId, senderId }: MessageType) => {
      setMessagesRoom((prev) => {
        return {
          ...prev,
          [room]: [
            ...(prev[room] || []),
            {
              date: new Date(),
              message: message,
              recipientId,
              room,
              senderId: senderId,
              user: user,
            },
          ],
        };
      });
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }, 100);
    },
    [user]
  );

  useEffect(() => {
    socket.on('receive_message', (data: MessageType) => {
      handleAddMessageByRoom(data);
    });
    socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });
    return () => {
      socket.off('receive_message');
    };
  }, [handleAddMessageByRoom]);
  useEffect(() => {
    const handleGetChats = async () => {
      if (user.id) {
        try {
          setLoading(true);
          const getChats = await getAllChats({ user_id: user.id });
          if (getChats.success) {
            setMessagesRoom(getChats.chats);
            setRooms(getChats.listChats);
          } else {
            if (getChats.message === "DON'T FOUND USERCHATS") {
              setMessagesRoom({});
            }
          }
          if (chPerm([], user) && process.env.REACT_APP_ID_MAIN_USER === user?.id?.toString()) {
            const { users } = await getAllUsers();
            setUsers(users);
          }

          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    handleGetChats();
  }, [user]);

  const getLastMesages = () => {
    let newLastsMessages: { [key: string]: string } = {};
    Object.keys(messagesRoom).forEach((item) => {
      const lastMessage = messagesRoom[item][messagesRoom[item]?.length - 1];
      newLastsMessages[item] = `${
        lastMessage?.senderId === user.id.toString()
          ? t('app.messages.me')
          : t('app.messages.other')
      }: ${lastMessage.message}`;
    });

    return newLastsMessages;
  };

  const lastMessage = getLastMesages();
  const roomsCreated = Object.keys(messagesRoom);
  const ableUsersToChat = users.filter(
    (item) => !roomsCreated.includes(`${item.id}`) && item.id !== user.id
  );

  return (
    <>
      {!loading ? (
        <Container>
          <div className="container-pick-chats">
            <ChatsLayout
              setCurrentRoom={setCurrentRoom}
              currentRoom={currentRoom}
              socket={socket}
              lastMessage={lastMessage}
              users={users}
              listRooms={rooms}
              ableUsers={ableUsersToChat}
            />
          </div>
          <div className="container-current-chat">
            <InputBar
              currentRoom={currentRoom}
              socket={socket}
              handleAddMessageByRoom={handleAddMessageByRoom}
              nameWriter={nameWriter}
            />
            <div className={`all-messages-container`} ref={scrollRef}>
              {(messagesRoom[currentRoom.id] || []).map((item, i) => (
                <MessageContainer mymessage={item.senderId === user.id.toString()} key={i}>
                  <p>{item.message}</p>
                </MessageContainer>
              ))}
            </div>
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};
