import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { DataContext } from '../../../../../../contexts';

export const GetClientsColumns = () => {
  const { t } = useTranslation();
  return [
    {
      field: 'name',
      headerName: t('tables.clients-table.name'),
    },
    {
      field: 'nit',
      headerName: 'NIT',
    },
    {
      field: 'verify_code',
      headerName: t('tables.clients-table.verify-code'),
    },
    {
      field: 'representative',
      headerName: t('tables.clients-table.representative'),
    },
    {
      field: 'representative_id',
      headerName: t('tables.clients-table.representative-id'),
    },
    {
      field: 'type',
      headerName: t('tables.clients-table.type'),
    },
    {
      field: 'num_personality',
      headerName: t('tables.clients-table.num-personality'),
    },
    {
      cellRenderer: (props: ICellRendererParams<string>) => (
        <span
          style={{
            backgroundColor: props.value,
            display: 'block',
            textAlign: 'center',
          }}
        >
          {props.value}
        </span>
      ),
      field: 'calendar_color',
      headerName: 'Color',
    },
  ];
};

export const GetUsersColumns = () => {
  const { t } = useTranslation();
  const { clients } = useContext(DataContext);
  return [
    {
      field: 'name',
      headerName: t('tables.users-table.name'),
    },
    {
      field: 'email',
      headerName: t('tables.users-table.email'),
    },
    {
      field: 'role',
      headerName: t('tables.users-table.role'),
    },
    {
      cellRenderer: (props: ICellRendererParams<string>) => (
        <span
          style={{
            backgroundColor: props.value,
            display: 'block',
            textAlign: 'center',
          }}
        >
          {clients.find((item) => item.id === props.value)?.name || ''}
        </span>
      ),
      field: 'client_id',
      headerName: t('tables.users-table.client-name'),
    },
  ];
};

export const GetInstructivessColumns = () => {
  const { t } = useTranslation();
  return [
    {
      field: 'title',
      headerName: t('tables.instructives-table.title'),
    },
    {
      field: 'url',
      headerName: 'Url',
    },
    {
      field: 'category',
      headerName: t('tables.instructives-table.category'),
    },
    {
      cellRenderer: (props: ICellRendererParams<string>) => (
        <span
          style={{
            backgroundColor: props.value,
            display: 'block',
            textAlign: 'center',
          }}
        >
          {props?.value?.length > 200 ? props?.value.slice(0, 80) + '...' : props?.value}
        </span>
      ),
      field: 'description',
      headerName: t('tables.instructives-table.description'),
    },
  ];
};
