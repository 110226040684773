import styled from '@emotion/styled';

export const CertificatesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .cont-items {
    border: 2px solid #4b4b4b;
    border-radius: 20px;
    min-width: 60%;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 20px;
      margin: 0;
      cursor: pointer;
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }
      button.MuiButtonBase-root.MuiButton-root {
        svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
          fill: #4b4b4b;
        }
      }
    }
    li:hover {
      background-color: #f6cbc9;
      button.MuiButtonBase-root.MuiButton-root {
        svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
          fill: currentColor;
        }
      }
    }
  }
`;
