import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export const FormControlStyled = styled(FormControl)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;
export const TextFieldStyled = styled(TextField)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;
