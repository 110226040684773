import { saveAs } from 'file-saver';
import { AlignmentType, Document, Packer, Paragraph, TextRun } from 'docx';

type InputsType = { [key: string]: { placeholder: string; value: string } };
export const generateDocumentIncomes = (params: InputsType) => {
  const font = 'Arial';
  const breakSpace = 1;

  const {
    name,
    ID,
    profesional_card,
    name2,
    ID2,
    amount_string,
    amount_number,
    city,
    profession,
    phone,
    day,
    month,
    year,
    dayString,
  } = params;

  const underlineText = (text: string) => {
    // return text
    //   .split('')
    //   .map((char) => char + '\u0332')
    //   .join('');
    return text;
  };

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `${underlineText(name.value)} con cedula de ciudadanía número ${underlineText(
                  ID.value
                )} de Medellín, Contador Público titulado de la Universidad Jaime Isaza Cadavid, con tarjeta profesional número ${underlineText(
                  profesional_card.value
                )}.`,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                text: 'CERTIFICA:',
              }),
            ],
            spacing: {
              after: 800,
              before: 800,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `El señor(a) ${underlineText(
                  name2.value
                )} identificada con cédula de ciudadanía número ${underlineText(ID2.value)}`,
              }),
              new TextRun({
                break: breakSpace,
                font: font,
                text: `percibe ingresos brutos mensuales promedio de (${underlineText(
                  amount_string.value
                )} pesos) ($${underlineText(amount_number.value)}); `,
              }),
              new TextRun({
                break: breakSpace,
                font: font,
                text: `provenientes de su actividad que desarrolla como ${underlineText(
                  profession.value
                )}. No posee embargos ni demandas.`,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `Su domicilio en la ciudad de ${underlineText(
                  city.value
                )} y su teléfono ${underlineText(phone.value)} `,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                font: font,
                text: `Dado en Medellín, a los ${underlineText(dayString.value)} (${underlineText(
                  day.value
                )}) días del mes de ${underlineText(month.value)} del año ${underlineText(
                  year.value
                )}.`,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            children: [new TextRun({ font: font, text: 'Cordialmente.' })],
            spacing: { after: 1000 },
          }),
          new Paragraph({
            children: [new TextRun('________________________________________________')],
            spacing: { after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: font,
                text: `${underlineText(name.value)}`,
              }),
            ],
            spacing: { after: 200 },
          }),
          new Paragraph({
            children: [new TextRun({ font: font, text: `C.C.  ${underlineText(ID.value)}` })],
            spacing: { after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({ font: font, text: `T.P. ${underlineText(profesional_card.value)}` }),
            ],
            spacing: { after: 200 },
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((buffer) => {
    saveAs(buffer, 'Certificado_de_ingresos.docx');
  });
};
