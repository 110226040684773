import axios from 'axios';

import { getSession, logout } from './auth';

const baseURL = process.env.REACT_APP_NODE_ENV;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(
  function onIntercept(config) {
    if (config.headers) {
      config.headers.Authorization = getSession();
    }
    return config;
  },
  function onError(error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function onIntercept(response) {
    return response;
  },
  function onError(error) {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
