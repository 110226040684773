import { useState, MouseEvent, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';

import colFlag from '../../assets/images/colombiaFlag.png';
import usaFlag from '../../assets/images/usaFlag.png';
import { DataContext } from '../../contexts';
import { chPerm } from '../../helpers';
import { logout, validSessionToken } from '../../services';

export const Navbar = (props: { isMobile?: boolean }) => {
  const { isMobile } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();
  const { user } = useContext(DataContext);
  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultToolBarStyles = {
    boxSizing: 'border-box',
    fontFamily: 'Arial',
    fontWeight: 600,
    paddingBottom: '5px',
    paddingTop: '5px',
  };

  const stylesMobile = {
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const handleVerifyUser = () => {
    const session = validSessionToken();
    if (session) {
      return true;
    }
    logout();
  };
  return (
    <>
      {!isMobile && (
        <Box
          sx={{
            display: { lg: 'block', xs: 'none' },
            minHeight: 54,
            minWidth: '100%',
          }}
        />
      )}
      <Box
        sx={{
          display: { lg: 'block', xs: isMobile ? 'block' : 'none' },
          flexGrow: isMobile ? 0 : 1,
          position: { lg: 'fixed', xs: 'relative' },
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      >
        <AppBar
          position="static"
          sx={{
            background: '#fff  ',
            boxShadow: 0,
            'div.MuiToolbar-root': {
              alignItems: 'center',
              justifyContent: 'flex-end',
              maxHeight: isMobile ? '100%' : 54,
              minHeight: isMobile ? '100%' : 54,
              padding: 0,
            },
          }}
        >
          <Toolbar
            sx={isMobile ? { ...defaultToolBarStyles, ...stylesMobile } : defaultToolBarStyles}
          >
            {chPerm([], user) && (
              <Link to={'/administrar'} style={{ maxHeight: 40 }}>
                <SettingsIcon
                  fontSize="large"
                  sx={{
                    color: '#43494f',
                    cursor: 'pointer',
                    height: 40,
                    width: 45,
                  }}
                />
              </Link>
            )}
            <HelpIcon
              fontSize="large"
              sx={{ color: '#43494f', cursor: 'pointer', height: 35, width: 45 }}
            />
            <img
              alt="col flag"
              onClick={() => changeLanguage('es')}
              src={colFlag}
              width={55}
              height={35}
              style={{ cursor: 'pointer' }}
            />
            <img
              alt="usa flag"
              onClick={() => changeLanguage('en')}
              src={usaFlag}
              width={50}
              height={33}
              style={{ cursor: 'pointer' }}
            />

            {handleVerifyUser() && (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <p
                  style={{
                    color: 'rgb(75, 75, 75)',
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    margin: 0,
                  }}
                >
                  {user.name}
                </p>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  sx={{ color: '#43494f', padding: 0 }}
                >
                  <AccountCircle sx={{ height: 40, width: 60 }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  keepMounted
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => handleNavigate('/perfil')}
                    sx={{ fontFamily: 'Montserrat' }}
                  >
                    {t('app.layout.navbar.profile')}
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>{t('app.layout.navbar.log-out')}</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
