import { DragEvent, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { DataContext } from '../../../../contexts';
import { chPerm } from '../../../../helpers';
import { ReminderType } from '../../../../types';
import { MainText } from './styles-reminder-item';

export type ReminderItemPropsType = {
  reminder: ReminderType;
  color: string;
  client?: string;
};

export const ReminderItem = (props: ReminderItemPropsType) => {
  const {
    reminder: { due_date, text, id, start_date, client_id },
    color,
  } = props;

  const { user, clients } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnDrag = (e: DragEvent, itemId: string) => {
    e.dataTransfer?.setData('itemId', itemId);
  };

  const onClickDetail = () => {
    navigate(`/recordatorios?id=${id}`);
  };

  const onClickEdit = () => {
    navigate(`/recordatorios?id=${id}&edit=true`);
  };

  const clientName = clients.find((clientItem) => clientItem.id === client_id)?.name || '';
  const card = (
    <>
      <CardContent sx={{ overflow: 'hidden', padding: '8px' }}>
        <Typography
          sx={{ fontFamily: 'Montserrat', fontSize: 14, textAlign: 'right' }}
          color="text.secondary"
          gutterBottom
        >
          {`${start_date ? dayjs(start_date).format('DD/MM/YYYY') : ''} ${
            start_date ? '-' : ''
          } ${dayjs(due_date).format('DD/MM/YYYY')}`}
        </Typography>
        <MainText>
          {text.slice(0, 90)}
          {text.length > 90 && (
            <span style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => onClickDetail()}>
              {t('app.default-translations.show-more')}...
            </span>
          )}
        </MainText>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <div style={{ paddingLeft: '0px', width: '100%' }}>
          <Typography
            sx={{ fontFamily: 'Montserrat', fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            {clientName}
          </Typography>
        </div>
        <Button
          size="small"
          startIcon={<ModeEditIcon />}
          sx={{ color: '#e8e8e8', fontFamily: 'Montserrat', minWidth: '30px' }}
          onClick={onClickEdit}
        />
      </CardActions>
    </>
  );
  return (
    <Box
      sx={{ minWidth: { lg: '100%', xs: 300 } }}
      draggable={chPerm([], user)}
      onDragStart={(e) => handleOnDrag(e, `${id}`)}
    >
      <Card
        variant="outlined"
        sx={{ borderColor: color, borderRadius: '14px', maxHeight: '110px', minHeight: '110px' }}
      >
        {card}
      </Card>
    </Box>
  );
};
