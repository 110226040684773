import styled from '@emotion/styled';

export const ContainerAnimation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c91f31;
  flex-direction: column;
  div.content {
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      animation: rotate 2s linear;
      margin: 40px 0;
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  @media (max-width: 768px) {
    div.content {
      img {
        width: 180px;
      }
      h2 {
        font-size: 20px;
      }
    }
  }
`;

export const Text = styled.h2`
  color: #244c5b;
  display: block;
  font: bold 30px Arial;
  text-transform: uppercase;
  letter-spacing: -1px;
  ${Array.from('finanzasintegras').map(
    (item, index) => `span:nth-of-type(${index + 1}){
      animation: blackblur 2s ${0.15 * index}s 1 alternate;
    }`
  )}

  @keyframes blackblur {
    from {
      text-shadow: 0 0 72px #244c5b;
      color: transparent;
    }
    to {
      text-shadow: 0;
      color: #c91f31;
    }
  }
`;
