import { axiosInstance } from './proxy-base';
import { BookingType } from '../types';

export const editBooking = (body: BookingType) => {
  return axiosInstance
    .put<{ booking: BookingType }>('/appointments/bookings/update', body)
    .then((response) => response?.data || ({} as BookingType));
};

export const createBooking = (body: BookingType) => {
  return axiosInstance
    .post<{ booking: BookingType }>('/appointments/bookings/create', body)
    .then((response) => response.data);
};

export const getAllBookings = () => {
  return axiosInstance
    .post<{ bookings: BookingType[] }>('/appointments/bookings/all', {})
    .then((response) => response?.data || ([] as BookingType[]));
};
