import { UsersInterface, ClientsInterface, InstructiveInterface } from './components';

type PropsTabsWithTablesTYpe = {
  tabsValue: number;
};

export const TabsWithTables = (props: PropsTabsWithTablesTYpe) => {
  const { tabsValue } = props;
  return (
    <>
      {tabsValue === 0 && <ClientsInterface />}
      {tabsValue === 1 && <UsersInterface />}
      {tabsValue === 2 && <InstructiveInterface />}
    </>
  );
};
