import { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TabsWithTables, CalendarAvailability } from './components';
import { getCurrentUser } from '../../services';
export const Admin = () => {
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const navigate = useNavigate();

  const session = getCurrentUser();
  if (session?.role !== 'admin' && session?.role !== 'totalAccess') {
    navigate('/recordatorios');
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          aria-label="Tabs clients and users"
          centered
        >
          <Tab label={t('app.admin.tabs.clients')} value={0} />
          <Tab label={t('app.admin.tabs.users')} value={1} />
          <Tab label={t('app.admin.tabs.instructives')} value={2} />
          <Tab label={t('app.admin.tabs.availability')} value={3} />
        </Tabs>

        {(tabsValue === 0 || tabsValue === 1 || tabsValue === 2) && (
          <TabsWithTables tabsValue={tabsValue} />
        )}

        {tabsValue === 3 && <CalendarAvailability />}
      </Box>
    </div>
  );
};
