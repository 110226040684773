import { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { getCertificates, getUrlCertificate } from '../../services';
import { DataContext } from '../../contexts';
import { CertificateType } from '../../types';
import { CertificatesContainer } from './styles-certificates';
import { Loader } from '../../components';
import { chPerm } from '../../helpers';

export const CertificatesPage = () => {
  const { user, clients } = useContext(DataContext);
  const { t } = useTranslation();

  const [certificates, setCertificates] = useState<CertificateType[]>([]);
  const [loading, setLoading] = useState(false);
  const [clientSelected, setClientSelected] = useState<null | number>(null);

  const getInfo = useCallback(async (clientId: number) => {
    try {
      setCertificates([]);
      setLoading(true);
      const res = await getCertificates({ client_id: clientId });
      setCertificates(res.certificates);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user.client_id) {
      getInfo(user.client_id);
    }
  }, [user.client_id, getInfo]);

  const handleUrlCertificate = async (url: string) => {
    try {
      const res = await getUrlCertificate({ url });
      const link = document.createElement('a');
      link.href = res.url;
      link.setAttribute('download', 'true');
      link.setAttribute('target', '_blank');
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSelect = (value: string) => {
    setClientSelected(parseInt(value));
    getInfo(parseInt(value));
  };
  return (
    <CertificatesContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 style={{ fontFamily: 'Montserrat', fontWeight: 500 }}>
            {t('app.layout.sidebar.document-type.income-and-withholdings-certify')}
          </h2>
          {chPerm([], user) && (
            <FormControl
              variant="standard"
              sx={{ m: 1, marginBottom: 2, maxWidth: '230px', minWidth: '180px' }}
            >
              <InputLabel
                id="select-clients-label"
                sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}
              >
                {t('app.reminders.labels-create-reminder.client-name')}
              </InputLabel>
              <Select
                labelId="select-clients-label"
                id="select-clients"
                value={clientSelected || ''}
                onChange={(e) => handleChangeSelect(e.target.value as string)}
                label={t('app.reminders.labels-create-reminder.client-name')}
              >
                {clients.map((client, index) => (
                  <MenuItem
                    key={index}
                    value={client.id}
                    sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}
                  >
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {certificates.length > 0 ? (
            <ul className="cont-items">
              {certificates.map((item, index) => (
                <li key={index} onClick={() => handleUrlCertificate(item.url)}>
                  <p>{item.name}</p>
                  <Button size="small">
                    <CloudDownloadIcon />
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <p>Don't Found</p>
          )}
        </>
      )}
    </CertificatesContainer>
  );
};
