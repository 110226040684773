import { useState, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Modal, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { getAllUsers } from '../../../../../../services';
import { DataContext } from '../../../../../../contexts';
import { AgGridTable } from '../../../../../../components';
import { GetUsersColumns, FormUsers } from '..';
import { AuthUserType } from '../../../../../../types';
import { BarFiltersContainer, style } from '../common-styles';

export const UsersInterface = () => {
  const { t } = useTranslation();
  const { clients } = useContext(DataContext);

  const [usersData, setUsersData] = useState<AuthUserType[]>([]);
  const [selectedRow, setSelectedRow] = useState<AuthUserType[]>([]);
  const [modal, setModal] = useState('');
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<{ client_id?: number }>({});

  const handleGetUsersData = useCallback(async (filters?: { client_id?: number }) => {
    try {
      const res = await getAllUsers(filters || {});
      setUsersData(res.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setUsersData([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetUsersData();
  }, [handleGetUsersData]);

  const hadleChangeSelectedRows = (selectedRows: AuthUserType[]) => {
    setSelectedRow(selectedRows);
  };
  const handleOpenModalCreate = () => {
    setModal('create-user');
  };

  const handleOpenModaledit = () => {
    setModal('edit-user');
  };

  const handleClose = () => {
    setModal('');
  };
  return (
    <>
      <BarFiltersContainer>
        <Button
          endIcon={<AddIcon sx={{ color: '#e8e8e8' }} />}
          size="large"
          onClick={handleOpenModalCreate}
          sx={{
            '&:hover': {
              bgcolor: '#a7a4a4',
              span: {
                transform: 'scale(1.3)',
              },
            },
            bgcolor: '#d3d2d2',
            borderRadius: '20px',
            color: '#000',
            height: 'fit-content',
            marginTop: 2,
            padding: '8px 12px',
            textTransform: 'none',
          }}
        >
          {t('app.default-translations.create')}
        </Button>
        <Button
          endIcon={<ModeEditIcon sx={{ color: '#e8e8e8' }} />}
          size="medium"
          onClick={handleOpenModaledit}
          disabled={selectedRow.length === 0}
          sx={{
            '&:hover': {
              bgcolor: '#a7a4a4',
              span: {
                transform: 'scale(1.3)',
              },
            },
            bgcolor: '#d3d2d2',
            borderRadius: '20px',
            color: '#000',
            height: 'fit-content',
            marginTop: 2,
            padding: '8px 12px',
            textTransform: 'none',
          }}
        >
          {t('app.default-translations.edit')}
        </Button>
        <>
          <FormControl variant="standard" sx={{ m: 1, maxWidth: '230px', minWidth: '180px' }}>
            <InputLabel id="select-clients-label">{t('app.reminders.client-id')}</InputLabel>
            <Select
              labelId="select-clients-label"
              id="select-clients"
              value={filters.client_id}
              onChange={(e) => setFilters({ client_id: parseInt(`${e.target.value}`) })}
              label={t('app.reminders.client-id')}
            >
              {clients.map((client, index) => (
                <MenuItem key={index} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={loading}
            sx={{
              '&:hover': {
                bgcolor: '#a7a4a4',
              },
              bgcolor: '#43494f',
              borderRadius: '20px',
              marginTop: 2,
            }}
            variant="contained"
            onClick={() => {
              setLoading(true);
              handleGetUsersData(filters);
            }}
          >
            {t('app.requirements.search')}
          </LoadingButton>
        </>
      </BarFiltersContainer>

      <AgGridTable
        columnParameters={GetUsersColumns()}
        data={usersData}
        handleClick={hadleChangeSelectedRows}
      />
      <Modal
        open={modal === 'edit-user'}
        onClose={handleClose}
        aria-labelledby="modal-edit-user"
        aria-describedby="this is a modal used to modify the content of a user"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: '60%',
          }}
        >
          <FormUsers
            title={t('app.default-translations.edit')}
            action="edit"
            prevUserInfo={{ ...selectedRow[0], password: '' } as AuthUserType}
            onClose={handleClose}
            getUsers={async () => {
              handleGetUsersData();
              setSelectedRow([]);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'create-user'}
        onClose={handleClose}
        aria-labelledby="modal-create-user"
        aria-describedby="this is a modal used to create a user"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: '60%',
          }}
        >
          <FormUsers
            title={t('app.default-translations.create')}
            action="create"
            onClose={handleClose}
            getUsers={async () => {
              handleGetUsersData();
              setSelectedRow([]);
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
