import dayjs from 'dayjs';

import { ReminderType } from '../../../../types';

type PropsDetailReminderType = {
  reminder: ReminderType;
};

export const DetailReminder = (props: PropsDetailReminderType) => {
  const {
    reminder: { due_date, text, start_date },
  } = props;
  return (
    <div style={{ minHeight: 150, minWidth: '40vw' }}>
      <h1>{`${
        dayjs(start_date).format('DD/MM/YYYY') === 'Invalid Date'
          ? ''
          : dayjs(start_date).format('DD/MM/YYYY')
      } - ${dayjs(due_date).format('DD/MM/YYYY')} `}</h1>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
