import { useContext, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router';

import { Navbar, Sidebar } from '../../components';
import { DataContext } from '../../contexts';
import { getAllClients, getAllReminders } from '../../services';
import { ContentContainer } from './styles-user-layout';
import { getCurrentUser } from '../../services';
import { AuthUserType } from '../../types';

export const UserLayout = () => {
  const windowObject = window;
  const { user, setClients, reminders, setReminders, setUser, setLoadingData } = useContext(DataContext);

  const handlegetAllReminders = useCallback(async () => {
    try {
      setLoadingData(true)
      const res = await getAllReminders();
      setReminders(res.reminders);
      setLoadingData(false)
    } catch (error) {
      console.error(error);
      setReminders([]);
      setLoadingData(false)
    }
  }, [setReminders, setLoadingData]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const res = await getAllClients();
        setClients(res.clients);
      } catch (error) {
        console.error();
        setClients([]);
      }
    };
    getClients();
    handlegetAllReminders();
  }, [setClients, handlegetAllReminders]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user.id) {
      setUser({ ...user, password: '' } as AuthUserType);
    }
  }, [setUser]);    
  return (
    <div>
      <Navbar />
      <Sidebar window={windowObject} user={user} reminders={reminders} />
      <ContentContainer>
        <div className="safe-sidebar-size"></div>
        <div className="container-content">
          <Outlet />
        </div>
      </ContentContainer>
    </div>
  );
};
