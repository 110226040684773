import { useState, forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DataContext } from '../../../../contexts';
import { createRequirement, editRequirement } from '../../../../services';
import { chPerm } from '../../../../helpers';
import { ErrorAlert } from '../../../../components';
import { RequirementType } from '../../../../types';
import { ContainerInputDate, ButtonCalendar } from '../default-styles';
import { FormControlStyled, ContainerDatePicker, ContainerForm } from './styles-form-component';

type HandleTextFieldChangeType = (
  fieldName: keyof RequirementType,
  value: string | Date | number
) => void;

type PropsFormComponentType = {
  onClose: () => void;
  getRequirements: () => Promise<void>;
  title: string;
  action: 'create' | 'edit';
  prevRequirementInfo?: RequirementType;
};

type ErrorType = {
  client_id: boolean;
  due_date: boolean;
  status: boolean;
  text: boolean;
  user_id: boolean;
};

export const FormComponent = (props: PropsFormComponentType) => {
  const { clients, user } = useContext(DataContext);
  const { t } = useTranslation();
  const {
    onClose,
    getRequirements,
    title,
    action,
    prevRequirementInfo = {
      status: 'pending',
      target: '',
      text: '',
    } as RequirementType,
  } = props;

  const [requirement, setRequirement] = useState<RequirementType>(prevRequirementInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as ErrorType);

  const STATUS = [
    { label: t('app.requirements.requirements-status.in-process'), value: 'in process' },
    { label: t('app.requirements.requirements-status.pending'), value: 'pending' },
    { label: t('app.requirements.requirements-status.completed'), value: 'completed' },
  ];

  const HandleTextFieldChange: HandleTextFieldChangeType = (fieldName, value) => {
    setRequirement({ ...requirement, [fieldName]: value });
  };

  const verifyErrorContent = () => {
    let hasError = false;
    let errors = error;
    const paramsToVerify: string[] = ['due_date', 'status', 'text'];
    for (const property of paramsToVerify) {
      if (!(property in requirement) || !requirement[property as keyof RequirementType]) {
        hasError = true;
        errors = { ...errors, [property as keyof ErrorType]: true };
      }
      if (
        property === 'text' &&
        (requirement[property as keyof RequirementType] === '<p></p>' ||
          requirement[property as keyof RequirementType] === '<p><br></p>')
      ) {
        hasError = true;
        errors = { ...errors, text: true };
      }
      if (chPerm([], user) && !requirement.client_id) {
        hasError = true;
        errors = { ...errors, client_id: true };
      }
    }

    if (hasError) {
      setError(errors);
    }
    return hasError;
  };
  const handleSendInfo = async () => {
    try {
      setLoading(true);
      const verifyIfHasError = verifyErrorContent();
      if (verifyIfHasError) {
        setLoading(false);

        return;
      }
      if (action === 'create') {
        await createRequirement(requirement);
      }
      if (action === 'edit') {
        await editRequirement(requirement);
      }
      setLoading(false);
      await getRequirements();
      onClose();
    } catch (error) {
      ErrorAlert(t(`errors.server-error`));
      console.error(error);
      setLoading(false);
    }
  };
  const InputDate = forwardRef<
    HTMLInputElement,
    { value: Date | null | undefined; onClick?: () => void; label: string }
  >(({ value = '', onClick, label }, ref) => {
    return (
      <ContainerInputDate ref={ref}>
        <TextField
          label={label}
          variant="standard"
          onClick={onClick}
          fullWidth
          value={value}
          error={error.due_date}
          helperText={error.due_date ? t('app.forms.required') : ''}
          sx={{
            input: { fontFamily: 'Montserrat', fontWeight: 500 },
            label: { fontFamily: 'Montserrat', fontWeight: 500 },
          }}
        />
        <ButtonCalendar onClick={onClick}>
          <CalendarMonthIcon />
        </ButtonCalendar>
      </ContainerInputDate>
    );
  });

  return (
    <ContainerForm>
      <h2
        style={{
          color: '#a7a7a78d',
          fontFamily: 'Montserrat',
          fontSize: '2rem',
          fontWeight: 500,
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <ContainerDatePicker>
        <DatePicker
          selected={requirement?.due_date ? new Date(requirement?.due_date) : null}
          dateFormat="dd/MM/yy"
          onChange={(date) => {
            if (date) {
              HandleTextFieldChange('due_date', date);
            }
          }}
          shouldCloseOnSelect={false}
          customInput={
            <InputDate
              value={requirement.due_date}
              label={t('app.requirements.labels-create-requirement.due-date')}
            />
          }
          popperClassName="some-custom-class"
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                rootBoundary: 'viewport',
                tether: false,
              },
            },
          ]}
        />
      </ContainerDatePicker>
      {chPerm([], user) && (
        <FormControlStyled variant="standard" sx={{ m: 1 }} error={error.client_id}>
          <InputLabel
            id="select-clients-form-label"
            sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}
          >
            {t('app.requirements.labels-create-requirement.client-name')}
          </InputLabel>
          <Select
            labelId="select-clients-form-label"
            id="select-clients-form"
            error={error.client_id}
            value={requirement.client_id}
            onChange={(e) => HandleTextFieldChange('client_id', e.target.value)}
            label={t('app.requirements.labels-create-requirement..client-name')}
          >
            {clients.map((client, index) => (
              <MenuItem key={index} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
          {error.client_id && <FormHelperText>{t('app.forms.required')}</FormHelperText>}
        </FormControlStyled>
      )}
      {action === 'edit' && (
        <FormControlStyled variant="standard" sx={{ m: 1 }} error={error.status}>
          <InputLabel id="select-status-form-label">
            {t('app.requirements.labels-create-requirement.status')}
          </InputLabel>
          <Select
            labelId="select-status-form-label"
            id="select-status-form"
            error={error.status}
            value={requirement.status}
            onChange={(e) => HandleTextFieldChange('status', e.target.value)}
            label={t('app.requirements.labels-create-requirement.status')}
          >
            {STATUS.map((state, index) => (
              <MenuItem key={index} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
          {error.status && <FormHelperText>{t('app.forms.required')}</FormHelperText>}
        </FormControlStyled>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <TextField
          label={t('app.reminders.labels-create-reminder.text')}
          value={requirement.text}
          onChange={(e) => HandleTextFieldChange('text', e.target.value)}
          error={error.text}
          multiline
          maxRows={4}
          variant="standard"
          sx={{ width: '80%' }}
        />
      </div>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center', width: '100%' }}>
        <Button
          size="large"
          variant="contained"
          onClick={onClose}
          sx={{ fontFamily: 'Montserrat', fontWeight: 500, marginTop: 5 }}
        >
          {t('app.reminders.exit')}
        </Button>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
            },
            bgcolor: '#42494f',
            color: '#fff',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            marginTop: 5,
          }}
        >
          {t('app.requirements.send')}
        </LoadingButton>
      </div>
    </ContainerForm>
  );
};
