import { axiosInstance } from './proxy-base';
import { ReminderType } from '../types';

export const editReminder = (body: ReminderType) => {
  return axiosInstance
    .put<{ reminder: ReminderType }>('/reminders/update', body)
    .then((response) => response?.data || ({} as ReminderType));
};

export const createReminder = (body: ReminderType) => {
  return axiosInstance
    .post<{ reminder: ReminderType }>('/reminders/create', body)
    .then((response) => response.data);
};

export const getAllReminders = (body?: {
  due_date_min?: Date;
  due_date_max?: Date;
  client_id?: number;
}) => {
  return axiosInstance
    .post<{ reminders: ReminderType[] }>('/reminders', body)
    .then((response) => response?.data || ([] as ReminderType[]));
};
