import styled from '@emotion/styled';

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0;
`;

export const ContainerBgInput = styled.div`
  background-color: #af1818;
  border-radius: 20px;
  color: #999999;
  max-width: 150px;
  padding: 10px;
  :hover {
    color: #fff;
  }
`;
