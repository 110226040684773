import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getAllinstructives } from '../../services';
import { InstructiveType } from '../../types';
import {
  InstructivesContainer,
  CategoryContainer,
  InstructiveItem,
  DetailVideoCont,
  VideoContainer,
} from './styles-instructive';
import { Loader } from '../../components';
import img from '../../assets/images/bg-video-det.png';
import water from '../../assets/images/water-brand.png';

type InstructivesType = { [key: string]: InstructiveType[] };
type SliderType = {
  [key: string]: {
    current: number;
    maxSlides: number;
    pixels: number;
  };
};

export const Instructive = () => {
  const [playing, setPlaying] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [instructives, setInstructives] = useState<InstructivesType>({});
  const [selectedVideo, setSelectedVideo] = useState<InstructiveType | null>(null);

  const [slideInfo, setSlideInfo] = useState<SliderType>({});

  const videoRef = useRef<ReactPlayer | null>(null);

  const groupByCategoria = (instructives: InstructiveType[]): InstructivesType => {
    return instructives.reduce((acc, instructive) => {
      const { category } = instructive;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(instructive);
      return acc;
    }, {} as InstructivesType);
  };

  useEffect(() => {
    const handleGetSlidesInfo = (data: InstructivesType) => {
      const res: SliderType = {};
      for (let key of Object.keys(data)) {
        const screen = window.screen.width;
        const cardWidth = 230;
        let numberOfCardsLess = Math.floor(screen / cardWidth) - 1;
        if (numberOfCardsLess === 0) {
          numberOfCardsLess = 1;
        }

        if (data[key].length <= numberOfCardsLess) {
          res[key] = {
            current: 0,
            maxSlides: 0,
            pixels: 0,
          };
        } else {
          const restCards = data[key].length - numberOfCardsLess;
          const numberOfScreens = Math.ceil(restCards / numberOfCardsLess);
          let pixels = Math.floor(cardWidth * numberOfCardsLess);
          if (restCards < numberOfCardsLess) {
            pixels = cardWidth * restCards;
          }
          res[key] = {
            current: 0,
            maxSlides: numberOfScreens + 1,
            pixels: pixels,
          };
        }
      }
      setSlideInfo(res);
    };
    const handleGetInstructives = async () => {
      try {
        setLoading(true);
        const res = await getAllinstructives();
        const getFormat = groupByCategoria(res.instructives);
        handleGetSlidesInfo(getFormat);
        setInstructives(getFormat);
        setLoading(false);
      } catch (error) {
        setInstructives({});
        setLoading(false);
      }
    };
    handleGetInstructives();
  }, []);

  const getYoutubeVideoId = (url: string) => {
    const regex = /[?&]v=([^&#]+)/;
    const match = url.match(regex);
    if (match) {
      const allUrl = `https://img.youtube.com/vi/${match[1]}/0.jpg`;
      return allUrl;
    }
    return '';
  };

  const goToPrevious = (key: string) => {
    if (slideInfo[key]) {
      const isFirstSlide = slideInfo[key].current === 0;
      if (!isFirstSlide) {
        const prevInfo = slideInfo[key];
        const newCurrent = slideInfo[key].current - 1;
        setSlideInfo((prev) => {
          return { ...prev, [key]: { ...prevInfo, current: newCurrent } };
        });
      }
    }
  };

  const goToNext = (key: string) => {
    if (slideInfo[key]) {
      const isLastSlide = slideInfo[key].current === slideInfo[key].maxSlides - 1;
      if (!isLastSlide) {
        const prevInfo = slideInfo[key];
        const newCurrent = slideInfo[key].current + 1;
        setSlideInfo((prev) => {
          return { ...prev, [key]: { ...prevInfo, current: newCurrent } };
        });
      }
    }
  };

  return (
    <div style={{ boxSizing: 'border-box', paddingRight: 40, position: 'relative' }}>
      {!loading ? (
        <>
          {!selectedVideo && (
            <InstructivesContainer>
              {Object.keys(instructives).map((category) => (
                <CategoryContainer key={category}>
                  <div className="title-cont">
                    <h3>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</h3>
                  </div>
                  <div
                    className="contain-items"
                    style={{
                      transform: `translateX(-${
                        slideInfo[category].current * slideInfo[category].pixels
                      }px)`,
                    }}
                  >
                    {slideInfo[category].current !== 0 && (
                      <div
                        className="cont-arrow left"
                        style={{
                          left: `${
                            slideInfo[category].current * slideInfo[category].pixels - 40
                          }px`,
                        }}
                      >
                        <ArrowBackIcon onClick={() => goToPrevious(category)} fontSize="large" />
                      </div>
                    )}
                    {slideInfo[category].current !== slideInfo[category].maxSlides - 1 && (
                      <div
                        className="cont-arrow right"
                        style={{
                          right:
                            slideInfo[category].current > 0
                              ? `-${
                                  slideInfo[category].current * slideInfo[category].pixels - 10
                                }px`
                              : '10px',
                        }}
                      >
                        <ArrowForwardIcon onClick={() => goToNext(category)} fontSize="large" />
                      </div>
                    )}
                    {instructives[category].map((video, index) => (
                      <InstructiveItem key={category + index}>
                        <div
                          className="cont-screen-video"
                          onClick={() => {
                            setSelectedVideo(video || null);
                          }}
                        >
                          <img src={getYoutubeVideoId(video.url) || ''} alt="caricatura" />
                        </div>
                        <div className="cont-content">
                          <h5>{video.title}</h5>
                          <p>Finanzas Integras</p>
                        </div>
                      </InstructiveItem>
                    ))}
                  </div>
                </CategoryContainer>
              ))}
            </InstructivesContainer>
          )}
        </>
      ) : (
        <Loader />
      )}
      {selectedVideo && (
        <DetailVideoCont>
          <div className="img-cont">
            <div className="cont-logo">
              <div
                className="left"
                onClick={() => {
                  setSelectedVideo(null);
                }}
              >
                <ArrowBackIcon sx={{ height: 50, width: 50 }} />
              </div>
            </div>
            <img src={img} alt="sdsd" />
            <div className="cont-logo">
              <div className="right">
                <img src={water} alt="logo" />
              </div>
            </div>
          </div>
          <div className="content-cont">
            <VideoContainer>
              <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="350px"
                ref={videoRef}
                playing={playing}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
              />
            </VideoContainer>
            <div className="description">
              <p className="video-title">{selectedVideo.title}</p>
              <p className="video-description">{selectedVideo.description}</p>
            </div>
          </div>
        </DetailVideoCont>
      )}
    </div>
  );
};
