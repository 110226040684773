import { Dispatch, SetStateAction } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { SetImageType } from '../../types';

type UploadProfilePhotoPropsType = {
  name: string;
  labelDescription: string;
  imageFile: SetImageType;
  set_image: Dispatch<SetStateAction<SetImageType | undefined>>;
  inputs: { photo: string };
};

export const UploadProfilePhoto = (props: UploadProfilePhotoPropsType) => {
  const { name, labelDescription, imageFile, set_image, inputs } = props;
  const { t } = useTranslation();

  const uploadProfilePicInput = (formID: string, secString: string) => {
    return (
      <div className="button">
        <label className={`archive-upload`} htmlFor={formID} style={{ cursor: 'pointer' }}>
          {!imageFile && !inputs.photo && t('app.certificates.upload') + ' ' + secString}
          {(imageFile || inputs.photo) && t('app.certificates.change') + ' ' + secString}
        </label>

        <input
          onChange={(event) => fileChangedHandler(event, set_image)}
          type="file"
          id={formID}
          accept=".pdf, .doc, .docx"
          style={{ display: 'none' }}
        />
      </div>
    );
  };

  return <>{uploadProfilePicInput(name, labelDescription)}</>;
};

interface Event<T = EventTarget> {
  target: T;
}

export const fileChangedHandler = (
  event: Event<HTMLInputElement>,
  set_image: Dispatch<SetStateAction<SetImageType | undefined>>
) => {
  if (event?.target?.files?.[0]) {
    const fileExt = event?.target?.files?.[0]?.name?.split?.('.')?.pop() || '';
    if (!['pdf', 'doc', 'docx'].includes(fileExt.toLowerCase())) {
      return Swal.fire('Solo puedes subir imagenes en pdf, doc, docx');
    }
    try {
      const send = event?.target?.files?.[0] || '';
      set_image(send);
    } catch (err) {
      console.error(err);
    }
  }
};
