import styled from '@emotion/styled'

export const ProfileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    max-height: 80vh;

    .container {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container.inputs {
        width: 60%;
        flex-wrap: wrap;
        gap: 20px;

        h4 {
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        padding: 0 20px;
        max-height: 100%;
        .container {
            width: 100%;
        }
        .container.inputs {
            width: 100%;
        }
    }

`