import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export const ContainerForm = styled.div`
  min-width: 260px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: center;
  @media (max-width: 800px) {
    padding: 5px;
    box-sizing: border-box;
  }
`
export const FormControlStyled = styled(FormControl)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;
export const TextFieldStyled = styled(TextField)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  align-items: center;
  div.react-datepicker-wrapper {
    min-width: 260px;
    @media (max-width: 425px) {
      min-width: 200px;
    }
  }
`;
