import { axiosInstance } from './proxy-base';
import { CalendarAvailabilityType } from '../types';

export const editAvailability = (body: CalendarAvailabilityType) => {
  return axiosInstance
    .put<{ availability: CalendarAvailabilityType }>('/appointments/availabilities/update', body)
    .then((response) => response?.data || ({} as CalendarAvailabilityType));
};

export const createAvailability = (body: CalendarAvailabilityType) => {
  return axiosInstance
    .post<{ availability: CalendarAvailabilityType }>('/appointments/availabilities/create', body)
    .then((response) => response.data);
};

export const getAllAvailabilities = () => {
  return axiosInstance
    .post<{ availabilities: CalendarAvailabilityType[] }>('/appointments/availabilities/all', {})
    .then((response) => response?.data || ([] as CalendarAvailabilityType[]));
};

export const deleteAvailability = (body: { id: number }) => {
  return axiosInstance
    .post<{ success: boolean; availability: CalendarAvailabilityType }>(
      '/appointments/availabilities/delete',
      body
    )
    .then((response) => response?.data || ({} as CalendarAvailabilityType));
};
