import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import BalanceIcon from '@mui/icons-material/Balance';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';

import { editUser, verifyCode } from '../../services';
import { ToastAlert } from '../../components';
import { ContainerIcons, ContainerLogin, ItemsInfoContainer } from './styles-restore-password';
import logo from '../../assets/images/nobg-logo.png';
import logoTitle from '../../assets/images/nobg-fi-logo.png';
import bgImag from '../../assets/images/fondo-login.png';
import colFlag from '../../assets/images/colombiaFlag.png';
import usFlag from '../../assets/images/usaFlag.png';

type ErrorInputType = {
  password: boolean;
  password2: boolean;
};
export const RestorePassword = () => {
  const [dataUser, setDataUser] = useState({ email: '', id: '', password: '', password2: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorInput, setErrorInput] = useState<ErrorInputType>({
    password: false,
    password2: false,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const handleParams = async () => {
      try {
        if (params.code) {
          console.log(params);
          const paramCode = params.code;
          const res = await verifyCode({ code: paramCode });
          if (res.success) {
            ToastAlert.fire({
              text: t('app-auth.success-message'),
              title: 'Error',
            });
            setTimeout(() => {
              navigate('/iniciar-sesion');
            }, 2000);
          }
        } else {
          ToastAlert.fire({
            text: t('app-auth.errors.code'),
            title: 'Error',
          });
          setTimeout(() => {
            navigate('/iniciar-sesion');
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        ToastAlert.fire({
          text: t(`app.auth.errors.user`),
          title: 'Error',
        });
        setTimeout(() => {
          navigate('/iniciar-sesion');
        }, 2000);
      }
    };
    handleParams();
  }, [params, navigate, t]);
  const handleChangeTextFields = (value: string, name: string) => {
    setDataUser((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const sendInfo = async () => {
    await editUser(dataUser)
      .then(() => {
        setLoading(false);
        navigate('/iniciar-sesion');
      })
      .catch((error) => {
        setLoading(false);
        ToastAlert.fire({
          icon: 'error',
          title: t(`app.auth.${error?.response?.data?.message}`),
        });
      });
  };
  const handleSend = async () => {
    setLoading(true);
    const VARS_REQUIRED: string[] = ['password', 'password2'];
    let hasError = false;
    let errors = errorInput;

    for await (const [key, value] of Object.entries(dataUser)) {
      if (VARS_REQUIRED.includes(key) && value?.length === 0 && key !== 'general') {
        errors[key as keyof ErrorInputType] = true;
        hasError = true;
      } else {
        errors[key as keyof ErrorInputType] = false;
      }
    }
    setErrorInput({ ...errors });
    if (dataUser.password !== dataUser.password2) {
      hasError = true;
      errors.password = true;
      errors.password2 = true;
      ToastAlert.fire({
        text: t('app.auth.not-match'),
        title: 'Error',
      });
    }
    if (!hasError) {
      sendInfo();
    } else {
      setLoading(false);
    }
  };

  const commonStylesInput = {
    '.MuiInputBase-root': {
      bgcolor: '#fceaec',
      borderRadius: '20px',
    },
    div: {
      '.MuiInputBase-input': {
        fontFamily: 'Arial',
        padding: '12.5px 10px',
      },
    },
    'div::after': {
      borderBottom: '2px solid #43494f',
    },
    label: {
      '&.Mui-focused': {
        color: '#777777',
      },
      color: '#777777',
    },
  };

  return (
    <ContainerLogin>
      <ContainerIcons>
        <div className="container-flags">
          <HelpIcon
            sx={{ color: '#616161c3', cursor: 'pointer', height: 40, width: 60 }}
            onClick={() => navigate('/support')}
          />
          <img alt="USA" src={usFlag} onClick={() => changeLanguage('en')} />
          <img alt="Colombia" src={colFlag} onClick={() => changeLanguage('es')} />
        </div>
      </ContainerIcons>
      <div className="container-form">
        <img src={logo} alt="logo" style={{ height: 80, opacity: '20%', width: 80 }} />
        <img src={logoTitle} alt="logo-title" style={{ height: 70, marginTop: 10, width: 240 }} />
        <h2>{t('app.auth.title-change-pass')}</h2>
        <div className="bg-cont">
          <p className="label-input">{t('app.auth.password')}</p>
          <FormControl
            sx={{
              ...commonStylesInput,
              m: 0,
              marginBottom: '20px',
              width: '100%',
            }}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={dataUser.password}
              onChange={(e) => handleChangeTextFields(e.target.value, 'password')}
              error={errorInput.password}
              disabled={loading}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '5px' }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ height: 35, width: 35 }} />
                    ) : (
                      <Visibility sx={{ height: 35, width: 35 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <p className="label-input">{t('app.auth.password')}</p>
          <FormControl
            sx={{
              ...commonStylesInput,
              m: 0,
              width: '100%',
            }}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={dataUser.password2}
              onChange={(e) => handleChangeTextFields(e.target.value, 'password2')}
              error={errorInput.password2}
              disabled={loading}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '5px' }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ height: 35, width: 35 }} />
                    ) : (
                      <Visibility sx={{ height: 35, width: 35 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className="buttons-container">
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSend}
              sx={{
                '&:hover': { bgcolor: '#c9233b' },
                bgcolor: '#E94F50',
                border: '1px solid #fff',
                borderRadius: '14px',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: 500,
                marginTop: '0px',
                maxWidth: 150,
                width: 150,
              }}
            >
              {t('app.auth.login')}
            </LoadingButton>
          </div>
        </div>
        <ItemsInfoContainer>
          <div className="item">
            <AccountCircleRoundedIcon sx={{ height: 50, width: 70 }} />
            <p>{t('app.auth.administratives')}</p>
          </div>
          <div className="item">
            <PaidRoundedIcon sx={{ height: 50, width: 70 }} />
            <p>{t('app.auth.financial')}</p>
          </div>
          <div className="item">
            <div className="cont">
              <BalanceIcon sx={{ fill: '#fff', height: 35, width: 45 }} />
            </div>
            <p>{t('app.auth.tributaries')}</p>
          </div>
          <div className="item">
            <div className="cont">
              <CalculateRoundedIcon sx={{ fill: '#fff', height: 35, width: 45 }} />
            </div>
            <p>{t('app.auth.accountants')}</p>
          </div>
        </ItemsInfoContainer>
      </div>
      <div style={{ position: 'relative', width: '100%', zIndex: 1 }}>
        <div className="bg-image">
          <img src={bgImag} alt="bg img" />
        </div>
      </div>
    </ContainerLogin>
  );
};
