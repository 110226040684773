import styled from '@emotion/styled';

export const ContainerRemindersList = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 10px;

  & > :nth-child(2) {
    &::before,
    ::after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #bebdbd;
    }
    &::after {
      right: -10px;
    }
    &::before {
      left: -12px;
    }
  }
  div.container-reminders-items {
    width: 33%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: relative;
    h3 {
      color: #274e5d;
      margin: 0;
      padding: 18px 0 8px 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  div.container-reminders-items.active {
    filter: opacity(0.7);
  }

  @media (max-width: 1024px) {
    justify-content: flex-start;
    padding: 10px 10px 10px 0;
    box-sizing: border-box;
    overflow-x: scroll;
    div.container-reminders-items {
      min-width: 300px;
      width: 300px;
    }
  }
`;

export const BarFiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
