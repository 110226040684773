import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  max-height: calc(100vh - 110px);
  height: calc(100vh - 64px);
  min-height: calc(100vh - 64px);
  max-width: 100vw;
  width: 100%;
  background-color: #fff7f7;
  gap: 20px;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  div {
    &.container-pick-chats {
      display: block;
      grid-column-start: 1;
    }
    &.container-current-chat {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100%;
      grid-column-start: 2;
      background-color: rgba(38, 76, 91, 0.38);
      border-radius: 24px;
      div.all-messages-container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 0 0 10px;
        overflow-y: scroll;
        max-height: 70vh;
        scrollbar-width: thin;
        scrollbar-color: #a3a3a3 transparent;
      }
    }
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props: { mymessage: boolean }) =>
    props.mymessage ? 'flex-end' : 'flex-start'};
  overflow: visible;
  flex-wrap: wrap;
  p {
    background-color: ${(props) =>
      props.mymessage ? 'rgba(38, 76, 91, 1)' : 'rgba(38, 76, 91, 1)'};
    color: rgba(255, 255, 255, 1);
    padding: 20px 25px;
    margin: 10px 10px 10px 0;
    border-radius: 15px;
    font-size: 16px;
    word-wrap: break-word;
    white-space: normal;
    max-width: 80%;
    font-weight: 500;
  }
`;
