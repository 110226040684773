import { useState, useEffect, useCallback, useContext } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Modal, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

import { DetailEvent, FormComponent } from './components';
import { getAllBookings } from '../../services';
import { DataContext } from '../../contexts';
import { BookingType } from '../../types';
import './Appointment-bookings-styles.css';

export const AppointmentBookings = () => {
  const { t, i18n } = useTranslation();
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [modal, setModal] = useState('');
  const [selectedBooking, setSelectedBooking] = useState<BookingType>({} as BookingType);
  const { clients } = useContext(DataContext);
  dayjs.locale(i18n.language);

  const localizer = dayjsLocalizer(dayjs);
  const bgColorSelectedBooking =
    clients.find((client) => client.id === selectedBooking.client_id)?.calendar_color || '#dfdddc';

  const handleGetBookings = useCallback(async () => {
    try {
      const res = await getAllBookings();
      if (res?.bookings) {
        const newData = res.bookings.map((item) => {
          return {
            ...item,
            end_time: new Date(item.end_time),
            start_time: new Date(item.start_time),
          };
        });
        setBookings(newData);
      }
    } catch (error) {
      console.error(error);
      setBookings([]);
    }
  }, []);

  useEffect(() => {
    handleGetBookings();
  }, [handleGetBookings]);

  const bookingstyleGetter = (myBookingsList: BookingType) => {
    const findClientColor = clients.find((client) => client.id === myBookingsList.client_id);
    const backgroundColor = findClientColor ? findClientColor.calendar_color : '#3174ad';
    return {
      style: {
        backgroundColor,
        border: 'none',
        color: myBookingsList?.in_person ? '#fff' : '#000',
      },
    };
  };

  const messages = {
    day: t('app.bookings.day'),
    month: t('app.bookings.month'),
    next: t('app.bookings.next'),
    previous: t('app.bookings.previous'),
    today: t('app.bookings.today'),
    week: t('app.bookings.week'),
  };

  const handleSelected = (event: BookingType) => {
    setSelectedBooking(event);
    setModal('detail');
  };

  const onCLose = () => {
    setModal('');
  };

  const style = {
    bgcolor: '#fff',
    border: '1px solid #9c9a9a',
    borderRadius: '20px',
    boxShadow: 24,
    left: '50%',
    overFlowY: 'scroll',
    p: { md: 4, xs: 0 },
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: '10px',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <div style={{ width: '100%' }}>
        <Calendar
          localizer={localizer}
          events={bookings}
          startAccessor="start_time"
          endAccessor="end_time"
          messages={messages}
          style={{ minHeight: 500 }}
          eventPropGetter={bookingstyleGetter}
          onSelectEvent={handleSelected}
          views={['day', 'week', 'month']}
          defaultView="day"
        />
      </div>
      <div style={{ bottom: 20, position: 'fixed', right: 20 }}>
        <Button onClick={() => setModal('create')}>
          <AddIcon
            fontSize="large"
            sx={{
              '&:hover': {
                background: '#f6c8c6',
              },
              background: '#fbe0de',
              borderRadius: '50%',
              color: '#740633',
              padding: '8px',
            }}
          />
        </Button>
      </div>
      <Modal
        open={modal === 'detail'}
        onClose={onCLose}
        aria-labelledby="modal-detail-event"
        aria-describedby="this is a modal used to show the content of a event"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            bgcolor: bgColorSelectedBooking,
            width: { md: '60%', xs: '90%' },
          }}
        >
          <DetailEvent
            event={selectedBooking}
            bgcolor={bgColorSelectedBooking}
            setModal={setModal}
            handleGetBookings={handleGetBookings}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'edit'}
        onClose={onCLose}
        aria-labelledby="modal-edit-event"
        aria-describedby="this is a modal used to edit a event"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: { md: '60%', xs: '90%' },
          }}
        >
          <FormComponent
            action="edit"
            title={t('app.bookings.edit')}
            getEvents={handleGetBookings}
            onClose={onCLose}
            prevEventInfo={selectedBooking}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'create'}
        onClose={onCLose}
        aria-labelledby="modal-create-event"
        aria-describedby="this is a modal used to create a event"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: { md: '60%', xs: '90%' },
          }}
        >
          <FormComponent
            title={t('app.bookings.create')}
            action="create"
            getEvents={handleGetBookings}
            onClose={onCLose}
          />
        </Box>
      </Modal>
    </div>
  );
};
