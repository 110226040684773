import { axiosInstance } from './proxy-base';
import { InstructiveType } from '../types';

export const editInstructive = (body: InstructiveType) => {
  return axiosInstance
    .put<{ Instructive: InstructiveType }>('/instructives/update', body)
    .then((response) => response?.data || ({} as InstructiveType));
};

export const createInstructive = (body: InstructiveType) => {
  return axiosInstance
    .post<{ instructive: InstructiveType }>('/instructives/create', body)
    .then((response) => response.data);
};

export const getAllinstructives = (body?: {
  due_date_min?: Date;
  due_date_max?: Date;
  Instructive_id?: number;
}) => {
  return axiosInstance
    .post<{ instructives: InstructiveType[] }>('/instructives', body)
    .then((response) => response?.data || ([] as InstructiveType[]));
};
