import { jwtDecode } from 'jwt-decode';

import { axiosInstance } from './proxy-base';
import { AuthUserType } from '../types';

type SessionTokenType = {
  createdAt: string;
  email: string;
  exp: number;
  iat: number;
  id: number;
  name: string;
  role: string;
  client_id: number;
};

export const login = (body: AuthUserType) => {
  return axiosInstance.post('/auth/signin', body).then((response) => {
    const lastLocation = localStorage.getItem('lastLocation');
    localStorage.removeItem('lastLocation');
    window.location.replace(lastLocation || '/');
    localStorage.setItem('userToken', response?.data?.token);
    return response.data;
  });
};

export const singup = (body: AuthUserType) => {
  return axiosInstance.post('/auth/create', body).then((response) => {
    return response.data;
  });
};
export const getAllUsers = (body?: { client_id?: number; keyword?: string }) => {
  return axiosInstance
    .post<{ users: AuthUserType[] }>('/auth', body)
    .then((response) => response?.data || ([] as AuthUserType[]));
};

export const getOneUser = (body?: { client_id?: number; email?: string }) => {
  return axiosInstance
    .post<{ user: AuthUserType }>('/auth/get-user', body)
    .then((response) => response?.data || ({} as AuthUserType));
};

export const editUser = (body: AuthUserType | { password: string; password2: string }) => {
  return axiosInstance
    .put<{ user: AuthUserType }>('/auth/update', body)
    .then((response) => response?.data || ({} as AuthUserType));
};

export const sendVerifyCode = (body: { email: string }) => {
  return axiosInstance
    .post<{ success: Boolean }>('/auth/generate-code', body)
    .then((response) => response?.data || {});
};

export const verifyCode = (body: { code: string }) => {
  return axiosInstance
    .post<{ success: Boolean; type: 'string' }>('/auth/verify-code', body)
    .then((response) => response?.data || {});
};

export const logout = () => {
  const lastLocation =
    window.location.pathname !== '/iniciar-sesion' ? window.location.pathname : '/';
  localStorage.removeItem('userToken');
  localStorage.setItem('lastLocation', lastLocation);
  window.location.replace('/iniciar-sesion');
};

export const getSession = () => {
  return localStorage.getItem('userToken') || '';
};

export const validSessionToken = () => {
  if (getCurrentUser()?.exp) {
    return new Date() > new Date(getCurrentUser()?.exp);
  }
  return false;
};

export const getCurrentUser = (): SessionTokenType => {
  try {
    return jwtDecode(getSession());
  } catch {
    return {} as SessionTokenType;
  }
};
