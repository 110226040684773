import { useState, forwardRef, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DataContext } from '../../../../contexts';
import { createBooking, editBooking, getAllAvailabilities } from '../../../../services';
import { chPerm } from '../../../../helpers';
import { ErrorAlert, ToastAlert } from '../../../../components';
import { BookingType, CalendarAvailabilityType } from '../../../../types';
import { ContainerInputDate, ButtonCalendar } from '../default-styles';
import { ContainerDatePicker, FormControlStyled, ContainerForm } from './styles-form-component';

type HandleTextFieldChangeType = (
  fieldName: keyof BookingType,
  value: string | Date | boolean | number
) => void;

type PropsFormComponentType = {
  onClose: () => void;
  getEvents: () => Promise<void>;
  title: string;
  action: 'create' | 'edit';
  prevEventInfo?: BookingType;
};

type ErrorType = {
  end_time: boolean;
  start_time: boolean;
  title: boolean;
  client_id: boolean;
};

export const FormComponent = (props: PropsFormComponentType) => {
  const { t } = useTranslation();
  const { clients, user } = useContext(DataContext);
  const { onClose, getEvents, title, action, prevEventInfo = {} as BookingType } = props;

  const [newBooking, setNewBooking] = useState<BookingType>(prevEventInfo);
  const [allAvailableBookings, setAllAvailableBookings] = useState<CalendarAvailabilityType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as ErrorType);

  const getAllAvailabilitiesBookings = useCallback(async () => {
    try {
      const res = await getAllAvailabilities();

      if (res?.availabilities) {
        setAllAvailableBookings(res.availabilities);
      }
    } catch (error) {
      console.log(error);
      setAllAvailableBookings([]);
    }
  }, []);

  useEffect(() => {
    getAllAvailabilitiesBookings();
  }, [getAllAvailabilitiesBookings]);

  const getavailableSlots = useCallback(
    (date: Date) => {
      const getAvailableBookingsByDate = allAvailableBookings.filter((item) => {
        const bookingDate = new Date(item.start_time);

        return (
          bookingDate.getFullYear() === date.getFullYear() &&
          bookingDate.getMonth() === date.getMonth() &&
          bookingDate.getDate() === date.getDate()
        );
      });

      return getAvailableBookingsByDate.reduce((accumulator, currentValue) => {
        const newDateSlots = currentValue.slots.map((item) => new Date(item));
        return [...accumulator, ...newDateSlots];
      }, [] as Date[]);
    },
    [allAvailableBookings]
  );

  const getavailableSlotsToEndDate = useCallback(
    (date: Date | null) => {
      if (!date) {
        return [];
      }
      const slots = getavailableSlots(date);
      let result: Date[] = [];
      let dateHalfHour = new Date(date);
      let dateOneHour = new Date(date);
      dateHalfHour.setMinutes(new Date(date).getMinutes() + 30);
      dateOneHour.setMinutes(new Date(date).getMinutes() + 60);

      const getHoursToDateHalfHour = new Date(dateHalfHour).getHours();
      const getMinutesToDateHalfHour = new Date(dateHalfHour).getMinutes();

      slots.forEach((slot) => {
        if (
          slot.getHours() === getHoursToDateHalfHour &&
          slot.getMinutes() === getMinutesToDateHalfHour
        ) {
          result = [...result, new Date(dateHalfHour)];
        }
        if (
          slot.getHours() === new Date(dateOneHour).getHours() &&
          slot.getMinutes() === new Date(dateOneHour).getMinutes()
        ) {
          if (
            !slots.find((item) => {
              return (
                item.getHours() === getHoursToDateHalfHour &&
                item.getMinutes() === getMinutesToDateHalfHour
              );
            })
          ) {
            result = [...result, new Date(dateHalfHour)];
          } else {
            result = [...result, new Date(dateOneHour)];
          }
        }
      });

      return result;
    },
    [getavailableSlots]
  );

  const HandleTextFieldChange: HandleTextFieldChangeType = (fieldName, value) => {
    setNewBooking((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  const verifyErrorContent = () => {
    let hasError = false;
    let errors = error;
    const paramsToVerify: string[] = ['end_time', 'start_time', 'title'];
    for (const property of paramsToVerify) {
      if (!(property in newBooking) || !newBooking[property as keyof BookingType]) {
        hasError = true;
        errors = { ...errors, [property as keyof ErrorType]: true };
      }
    }

    if (hasError) {
      setError(errors);
    }
    return hasError;
  };

  const handleSendInfo = async () => {
    try {
      setLoading(true);
      const verifyIfHasError = verifyErrorContent();
      if (verifyIfHasError) {
        setLoading(false);
        return;
      }
      let res: { booking?: BookingType } = {};
      if (action === 'create') {
        res = await createBooking(newBooking);
      }
      if (action === 'edit') {
        res = await editBooking(newBooking);
      }
      if (!res?.booking) {
        getAllAvailabilities();
        ToastAlert.fire({
          icon: 'error',
          text: t('app.bookings.error-already-booked'),
        });
      }
      setLoading(false);
      await getEvents();
      onClose();
    } catch (error) {
      ErrorAlert(t(`errors.server-error`));
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangePicker = (date: Date | null, param: keyof BookingType) => {
    if (!date) {
      return;
    }
    if (param === 'end_time') {
      if (!newBooking?.start_time) {
        setError((prevErrors) => {
          return { ...prevErrors, start_time: true };
        });
        return;
      }
      const startDate = new Date(newBooking?.start_time);

      const differenceInMillisecons = date.getTime() - startDate.getTime();

      const differenceInMinutes = differenceInMillisecons / (1000 * 60);

      if (differenceInMinutes < 30 && differenceInMinutes > 60) {
        setError((prevErrors) => {
          return { ...prevErrors, end_time: true };
        });
        return;
      }
    }
    HandleTextFieldChange(param, date);
  };

  const InputDate = forwardRef<
    HTMLInputElement,
    { value: Date | null | undefined; onClick?: () => void; label: string }
  >(({ value = '', onClick, label }, ref) => {
    return (
      <ContainerInputDate ref={ref}>
        <TextField
          label={label}
          variant="standard"
          onClick={onClick}
          fullWidth
          value={value}
          error={error.start_time || error.end_time}
          helperText={error.start_time || error.end_time ? t('app.forms.required') : ''}
          sx={{
            input: { fontFamily: 'Montserrat', fontWeight: 500 },
            label: { fontFamily: 'Montserrat', fontWeight: 500 },
          }}
        />
        <ButtonCalendar onClick={onClick}>
          <CalendarMonthIcon />
        </ButtonCalendar>
      </ContainerInputDate>
    );
  });
  return (
    <ContainerForm>
      <h2
        style={{
          color: '#a7a7a78d',
          fontSize: '2rem',
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <ContainerDatePicker>
        <DatePicker
          selected={newBooking?.start_time ? new Date(newBooking?.start_time) : null}
          dateFormat="dd/MM/yy hh:mm"
          onChange={(e) => {
            HandleTextFieldChange('end_time', '');
            handleChangePicker(e, 'start_time');
          }}
          shouldCloseOnSelect={false}
          showTimeSelect
          customInput={
            <InputDate value={newBooking.start_time} label={t('app.bookings.labels.start')} />
          }
          popperClassName="some-custom-class"
          popperPlacement="top-end"
          includeTimes={getavailableSlots(
            newBooking?.start_time ? new Date(newBooking?.start_time) : new Date()
          )}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                rootBoundary: 'viewport',
                tether: false,
              },
            },
          ]}
        />
      </ContainerDatePicker>
      <ContainerDatePicker>
        <DatePicker
          selected={newBooking?.end_time ? new Date(newBooking?.end_time) : null}
          dateFormat="dd/MM/yy hh:mm"
          onChange={(e) => handleChangePicker(e, 'end_time')}
          shouldCloseOnSelect={false}
          showTimeSelect
          customInput={
            <InputDate value={newBooking.end_time} label={t('app.bookings.labels.end')} />
          }
          popperClassName="some-custom-class"
          popperPlacement="top-end"
          includeTimes={getavailableSlotsToEndDate(newBooking.start_time)}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                rootBoundary: 'viewport',
                tether: false,
              },
            },
          ]}
        />
      </ContainerDatePicker>
      {chPerm([], user) && (
        <FormControlStyled variant="standard" sx={{ m: 1 }} error={error.client_id}>
          <InputLabel id="select-clients-form-label">
            {t('app.bookings.labels.client-id')}
          </InputLabel>
          <Select
            labelId="select-clients-form-label"
            id="select-clients-form"
            error={error.client_id}
            value={newBooking.client_id}
            onChange={(e) => HandleTextFieldChange('client_id', e.target.value)}
            label={t('app.requirements.labels-create-requirement.client-id')}
          >
            {clients.map((client, index) => (
              <MenuItem key={index} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
          {error.client_id && <FormHelperText>{t('app.forms.required')}</FormHelperText>}
        </FormControlStyled>
      )}
      <FormControlLabel
        sx={{ justifyContent: 'center', margin: '0 auto' }}
        control={
          <Switch
            checked={newBooking.in_person || false}
            onChange={(e) => HandleTextFieldChange('in_person', e.target.checked)}
          />
        }
        label={t('app.bookings.labels.in-person')}
      />
      <TextField
        id="standard-multiline-flexible"
        label={t('app.bookings.labels.title')}
        multiline
        maxRows={4}
        variant="standard"
        value={newBooking.title}
        error={error.title}
        onChange={(e) => HandleTextFieldChange('title', e.target.value)}
        helperText={error.title ? t('app.forms.required') : ''}
        fullWidth
        sx={{ maxWidth: { sm: '60%', xs: '100%' } }}
      />

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
              color: '#000',
            },
            bgcolor: '#740633',
            color: '#fff',
            marginTop: 5,
          }}
        >
          {t('app.bookings.send')}
        </LoadingButton>
      </div>
    </ContainerForm>
  );
};
