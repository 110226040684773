import styled from '@emotion/styled';

export const ContainerLogin = styled.div`
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-start;
  div.container-form {
    width: 35%;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #43494f;
    background-color: transparent;
    position: relative;
    z-index: 2;
    > img {
      border-radius: 8px;
      height: 160px;
    }
    p.label-input {
      width: 80%;
      padding-left: 10px;
      margin: 0 0 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff9e;
    }
    h2 {
      color: #5b5b5b;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
      font-weight: 500;
      font-size: 25px;
    }
    div.MuiFormControl-root {
      width: 100%;
    }
    .user-interaction {
      width: 100%;
      margin-top: 20px;
      font-weight: 500;
      &p {
        margin: 0;
        text-align: start;
        font-weight: 500;
      }
      span {
        text-decoration: none;
        color: #dc565d;
        font-weight: 500;
        cursor: pointer;
      }
      &.forgot {
        text-align: right;
        margin: 15px 0 0 0;
        font-size: 14px;
        a {
          color: #8a4248;
        }
      }
    }
    .bg-cont {
      background-color: rgba(233, 79, 80, 0.7);
      width: 100%;
      box-sizing: border-box;
      padding: 16px;
      border-radius: 40px;
    }
    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;
      flex-direction: column;
    }
  }
  .bg-image {
    width: 100%;
    z-index: 1;
    position: fixed;
    bottom: -30px;
    img {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    max-height: 100%;
    div.container-form .bg-cont .buttons-container {
      justify-content: center;
    }
  }
  @media (max-width: 768px) {
    padding: 20px 0 40px;
    div.container-form {
      width: 90%;
      > img {
        width: 100%;
        max-width: 280px;
      }
      .bg-cont {
        .buttons-container {
          width: 100%;
        }
      }
      div.MuiFormControl-root {
        width: 100%;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fceaec inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
  z-index: 2;
  div.container-flags {
    display: flex;
    width: fit-content;
    img {
      cursor: pointer;
      width: 60px;
      height: 40px;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    justify-content: space-between;
    margin-bottom: 40px;
  }
`;

export const ItemsInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100px;
    .cont {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      background-color: #43494f;
      border-radius: 50%;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    .item {
      margin-top: 10px;
    }
  }
`;
