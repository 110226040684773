import { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';
import { LoadingButton } from '@mui/lab';

import { RequirementItem, Filters, DetailRequirement, FormComponent } from './components';
import { DataContext } from '../../contexts';
import { editRequirement, getAllRequirements } from '../../services';
import { ErrorAlert, Loader } from '../../components';
import { RequirementType, FiltersRequirementType } from '../../types';
import { ContainerRequirementsList, BarFiltersContainer } from './styles-requirements';

export const RequirementsPage = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FiltersRequirementType>({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState('');
  const [isDraggingOver, setIsDraggingOver] = useState(0);
  const [requirementSelected, setRequirementSelected] = useState<RequirementType>(
    {} as RequirementType
  );

  const { requirements, setRequirements, clients } = useContext(DataContext);
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const idrequirement = queryParams.get('id');
  const EditRequirementParam = queryParams.get('edit');

  const handleGetDetailrequirement = useCallback(
    (id: string) => {
      const requirement = requirements.find((item) => item.id === parseInt(id));
      if (requirement) {
        setRequirementSelected(requirement);
        return requirement;
      }
      return false;
    },
    [requirements]
  );

  const handlegetAllRequirements = useCallback(
    async (filters?: FiltersRequirementType) => {
      try {
        setLoading(true);
        let newFilters = { ...filters };
        if (!newFilters?.due_date_max && newFilters?.due_date_min) {
          newFilters = { ...newFilters, due_date_max: newFilters.due_date_min };
        }
        const res = await getAllRequirements(newFilters);
        setRequirements(res.requirements);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setRequirements([]);
        setLoading(false);
      }
    },
    [setRequirements]
  );

  useEffect(() => {
    handlegetAllRequirements();
  }, [handlegetAllRequirements]);

  useEffect(() => {
    if (idrequirement) {
      const getrequirement = handleGetDetailrequirement(idrequirement);
      if (getrequirement) {
        if (EditRequirementParam) {
          setModal('edit');
        } else {
          setModal('detail');
        }
      }
    }

    if (pathname.includes('crear')) {
      setModal('create');
    }
  }, [idrequirement, pathname, handleGetDetailrequirement, EditRequirementParam]);

  const filterrequirements = (statusType: string) =>
    requirements.filter((item) => item.status.toLowerCase() === statusType.toLowerCase());

  const requirementsInProcess = filterrequirements('in process');
  const requirementsPending = filterrequirements('pending');
  const requirementsCompleted = filterrequirements('completed');

  const handleEditRequirement = async (requirement: RequirementType) => {
    try {
      await editRequirement(requirement);
    } catch (error) {
      console.error(error);
      ErrorAlert(t(`errors.server-error`));
      handlegetAllRequirements(filters);
    }
  };

  const handleDrop = (e: DragEvent, newStatus: string) => {
    const itemId = e.dataTransfer.getData('itemId');
    const newState = requirements.map((requirement) => {
      if (requirement.id === parseInt(itemId)) {
        const newrequirement = { ...requirement, status: newStatus };
        handleEditRequirement(newrequirement);
        return newrequirement;
      }
      return requirement;
    });
    setRequirements(newState);
  };

  const handleClose = () => {
    setModal('');
    navigate('/requerimientos');
  };

  const bgColorRequirement = (requirement: RequirementType) => {
    return (
      clients.find((client) => client.id === requirement.client_id)?.calendar_color || '#dfdddc'
    );
  };

  const handleRestoreFilters = () => {
    setLoading(true);
    setFilters({});
    handlegetAllRequirements();
  };

  const style = {
    bgcolor: '#fff',
    border: '1px solid #9c9a9a',
    borderRadius: '20px',
    boxShadow: 24,
    left: '50%',
    overFlowY: 'scroll',
    p: { md: 4, xs: 0 },
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ width: '100%' }}>
      <BarFiltersContainer>
        <Button
          endIcon={<AddIcon sx={{ color: '#e8e8e8' }} />}
          size="large"
          onClick={() => navigate('/requerimientos/crear')}
          sx={{
            '&:hover': {
              bgcolor: '#a7a4a4',
              span: {
                transform: 'scale(1.3)',
              },
            },
            bgcolor: '#d3d2d2',
            borderRadius: '20px',
            color: '#000',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            height: 'fit-content',
            marginTop: 2,
            padding: '8px 12px',
            textTransform: 'none',
          }}
        >
          {t('app.requirements.create')}
        </Button>
        <Filters filters={filters} setFilters={setFilters} />
        <div style={{ alignItems: 'center', display: 'flex', gap: 5 }}>
          <IconButton aria-label="restore" onClick={handleRestoreFilters} sx={{ marginTop: 2 }}>
            <RestoreIcon />
          </IconButton>
          <LoadingButton
            loading={loading}
            sx={{
              '&:hover': {
                bgcolor: '#a7a4a4',
              },
              bgcolor: '#43494f',
              fontFamily: 'Montserrat',
              fontWeight: 500,
              marginTop: 2,
            }}
            variant="contained"
            onClick={() => {
              setLoading(true);
              handlegetAllRequirements(filters);
            }}
          >
            {t('app.requirements.search')}
          </LoadingButton>
        </div>
      </BarFiltersContainer>

      {!loading ? (
        <ContainerRequirementsList>
          <div
            className={`container-requirements-items ${isDraggingOver === 1 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(1);
            }}
            onDrop={(e) => {
              handleDrop(e, 'pending');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.requirements.requirements-status.pending')} (${
              requirementsPending.length
            })`}</h3>
            {requirementsPending.map((requirement, index) => (
              <RequirementItem
                key={index}
                requirement={requirement}
                color={bgColorRequirement(requirement)}
              />
            ))}
          </div>
          <div
            className={`container-requirements-items ${isDraggingOver === 2 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(2);
            }}
            onDrop={(e) => {
              handleDrop(e, 'in process');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.requirements.requirements-status.in-process')} (${
              requirementsInProcess.length
            })`}</h3>
            {requirementsInProcess.map((requirement, index) => (
              <RequirementItem
                key={index}
                requirement={requirement}
                color={bgColorRequirement(requirement)}
              />
            ))}
          </div>
          <div
            className={`container-requirements-items ${isDraggingOver === 3 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(3);
            }}
            onDrop={(e) => {
              handleDrop(e, 'completed');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.requirements.requirements-status.completed')} (${
              requirementsCompleted.length
            })`}</h3>
            {requirementsCompleted.map((requirement, index) => (
              <RequirementItem
                key={index}
                requirement={requirement}
                color={bgColorRequirement(requirement)}
              />
            ))}
          </div>
        </ContainerRequirementsList>
      ) : (
        <Loader />
      )}
      <Modal
        open={modal === 'detail'}
        onClose={handleClose}
        aria-labelledby="modal-detai"
        aria-describedby="this is a modal used to see all the content of a requirement"
      >
        <Box sx={{ ...style, borderColor: bgColorRequirement(requirementSelected) }}>
          <DetailRequirement requirement={requirementSelected} />
        </Box>
      </Modal>
      <Modal
        open={modal === 'create'}
        onClose={handleClose}
        aria-labelledby="modal-create-requirement"
        aria-describedby="this is a modal used to add the content to a new requirement"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box sx={{ ...style, width: { md: '60%', xs: '90%' } }}>
          <FormComponent
            title={t('app.requirements.create')}
            action="create"
            onClose={handleClose}
            getRequirements={async () => {
              handlegetAllRequirements(filters);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'edit'}
        onClose={handleClose}
        aria-labelledby="modal-edit-requirement"
        aria-describedby="this is a modal used to modify the content of a requirement"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            top: '50%',
            transform: `translate(-50%, -40%)`,
            width: { md: '60%', xs: '90%' },
          }}
        >
          <FormComponent
            title={t('app.requirements.edit')}
            action="edit"
            prevRequirementInfo={requirementSelected}
            onClose={handleClose}
            getRequirements={async () => {
              handlegetAllRequirements(filters);
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};
