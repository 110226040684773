import { axiosInstance } from './proxy-base';
import { ChatType, ListChatType } from '../types';

export const addChat = (body: { user_id: number; room_id: number }) => {
  return axiosInstance
    .post<{ success: boolean; message: string; chatId: string }>('/messages/create', body)
    .then((response) => response.data);
};

export const getAllChats = (body?: {
  due_date_min?: Date;
  due_date_max?: Date;
  user_id: number;
}) => {
  return axiosInstance
    .post<{
      success: Boolean;
      message: string;
      chats: { [key: string]: ChatType };
      listChats: ListChatType;
    }>('/messages/all', body)
    .then((response) => response?.data || ({} as { [key: string]: ChatType }));
};
