import styled from '@emotion/styled';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgba(38, 76, 91, 0.38);
  border-radius: 24px;
`;

export const RoomItem = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 5px 15px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#fbfbfb4f' : 'transparent'};
  /* box-shadow: 0 -0.5px 0 0 rgb(209, 215, 219); */
  cursor: pointer;
  border-radius: 24px 24px 0px 0;

  p {
    margin: 5px 0 0;
    display: block;
    font-weight: 600;
    &.name-room {
      color: rgba(38, 76, 91, 1);
      font-size: 16px;
    }
    &.last-message {
      color: rgba(38, 76, 91, 1);
      font-size: 14px;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    height: 120px;
    gap: 5px;
    padding: 10px 0;
  }
`;

export const Rounded = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

export const ContainerAddRoom = styled.div`
  position: absolute;
  right: 5%;
  bottom: 3%;
  padding: 10px;
  border-radius: 50%;
  background: #c8233b;
  cursor: pointer;
  @media (max-width: 900px) {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MobileContainerRoomItem = styled.div`
  display: flex;
  overflow-x: scroll;
`;

export const MobileContainerAddRoom = styled.div`
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 10;
`;
