import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';

export const ContainerInputDate = styled(FormControl)`
  width: 100%;
  position: relative;
  font-family: Montserrat;
  font-weight: 500;
  min-width: 290px;
`;

export const ButtonCalendar = styled.button`
  position: absolute;
  top: 35%;
  right: 5%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-weight: 500;
  div.react-datepicker-wrapper {
    min-width: 260px;
  }
`;
