import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

export const BarFiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const style = {
  bgcolor: '#dfdddc',
  border: '1px solid #9c9a9a',
  borderRadius: '20px',
  boxShadow: 24,
  left: '50%',
  overFlowY: 'scroll',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export const FormControlStyled = styled(FormControl)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;
