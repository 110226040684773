import { createContext, ReactNode, Dispatch, SetStateAction, useState } from 'react';

import { AuthUserType, ReminderType, ClientType, RequirementType } from '../types';

const DataContext = createContext({} as DataState);

type DataState = {
  clients: ClientType[];
  setClients: Dispatch<SetStateAction<ClientType[]>>;
  user: AuthUserType;
  setUser: Dispatch<SetStateAction<AuthUserType>>;
  reminders: ReminderType[];
  setReminders: Dispatch<SetStateAction<ReminderType[]>>;
  requirements: RequirementType[];
  setRequirements: Dispatch<SetStateAction<RequirementType[]>>;
  loadingData: boolean;
  setLoadingData: Dispatch<SetStateAction<boolean>>;

};

const DataProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState({} as AuthUserType);
  const [reminders, setReminders] = useState<ReminderType[]>([]);
  const [requirements, setRequirements] = useState<RequirementType[]>([]);
  const [loadingData, setLoadingData] = useState(false);


  const [clients, setClients] = useState<ClientType[]>([]);

  const dataState: DataState = {
    clients,
    loadingData,
    reminders,
    requirements,
    setClients,
    setLoadingData,
    setReminders,
    setRequirements,
    setUser,
    user,
  };

  return <DataContext.Provider value={dataState}>{children}</DataContext.Provider>;
};

export { DataContext, DataProvider };
