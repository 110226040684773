import styled from '@emotion/styled';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
export const Container = styled.div`
  width: 90%;
  height: 70px;
  box-sizing: border-box;
  padding: 15px 10px;
  display: ${(props: { ishidden: boolean }) => (props.ishidden ? 'none' : 'flex')};
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  input {
    padding: 5px 15px;
    font-size: 1rem;
    height: 100%;
    border: none;
    line-height: 0;
    border-radius: 0px 30px 30px 0px;
    width: 100%;
    :focus-visible {
      outline: none;
    }
  }
`;
export const ButtonStyled = styled.button`
  padding: 0;
  height: ${({ prev }: { prev: boolean }) => (!prev ? '50px' : '70px')};
  background-color: ${({ prev }: { prev: boolean }) => (!prev ? '#fff' : 'transparent')};
  border: none;
  cursor: pointer;
  border-radius: ${({ prev }: { prev: boolean }) => (!prev ? '30px 0 0 30px' : '0')};
  padding: ${({ prev }: { prev: boolean }) => (!prev ? '0 0 0 10px ' : '0')};
`;

export const FaceStyled = styled(SentimentSatisfiedAltIcon)`
  fill: ${(props: { openemoticons: string }) =>
    props.openemoticons === 'true' ? '#c8233b' : '#8696a0'};
`;

export const PickerContainer = styled.div`
  position: absolute;
  bottom: 72px;
  left: 0;
  width: 100%;
  div {
    width: 100%;
    height: 320px;
    em-emoji-picker {
      width: 100%;
      height: 320px;
    }
  }
`;
