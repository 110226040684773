import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import src from '../../assets/images/nobg-logo.png';

const spin = keyframes`
  0% { opacity: 0; }
  50% { opacity: 20%; }
  100% { opacity: 0; }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderImage = styled.img`
  width: 220px;
  height: 230px;
  animation: ${spin} 2s linear infinite;
  opacity: 20%;
`;

export const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderImage src={src} alt="Loading..." />
    </LoaderContainer>
  );
};
