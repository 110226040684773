import dayjs from 'dayjs';

import { RequirementType } from '../../../../types';

type PropsDetailRequirementType = {
  requirement: RequirementType;
};

export const DetailRequirement = (props: PropsDetailRequirementType) => {
  const {
    requirement: { due_date, text },
  } = props;
  return (
    <div style={{ minHeight: 150, minWidth: '40vw' }}>
      <h1>{`${dayjs(due_date).format('DD/MM/YYYY')}`}</h1>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
