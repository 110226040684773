import { saveAs } from 'file-saver';
import { AlignmentType, Document, Packer, Paragraph, TextRun } from 'docx';

type InputsType = { [key: string]: { placeholder: string; value: string | Date } };
export const generateDocumentWorkCertificate = (params: InputsType) => {
  const font = 'Arial';
  const breakSpace = 1;

  const {
    place,
    name,
    ID,
    represent,
    NIT,
    NIT_number,
    name_to,
    ID_to,
    incomes,
    concept,
    phone,
    type_doc,
  } = params;

  const underlineText = (text: string | Date) => {
    // return text
    //   .split('')
    //   .map((char) => char + '\u0332')
    //   .join('');
    return text;
  };

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            alignment: AlignmentType.START,
            children: [
              new TextRun({
                bold: true,
                font: font,
                text: `Medellín, ${place.value}`,
              }),
            ],
            spacing: {
              after: 800,
              before: 800,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 16,
                text: `A QUIEN CORRESPONDA`,
              }),
            ],
            spacing: {
              after: 1000,
              before: 800,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `Yo, ${underlineText(name.value)} identificado con ${
                  type_doc.value
                } No. ${underlineText(ID.value)} en representación de la ${underlineText(
                  represent.value
                )} NIT ${underlineText(NIT.value)} - ${underlineText(
                  NIT_number.value
                )}, Certifico que la señora ${underlineText(
                  name_to.value
                )} identificada con la cédula de ciudadanía No. ${underlineText(
                  ID_to.value
                )}, percibe unos ingresos mensuales de ${underlineText(
                  incomes.value
                )} por concepto de ${underlineText(concept.value)}`,
              }),
            ],
            spacing: {
              after: 600,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.START,
            children: [
              new TextRun({
                bold: true,
                font: font,
                text: 'Cualquier información adicional con gusto la suministraré en los ',
              }),
              new TextRun({
                bold: true,
                font: font,
                text: 'teléfonos referidos al final de ésta.',
              }),
            ],
            spacing: {
              after: 1000,
            },
          }),
          new Paragraph({
            children: [new TextRun(`TEL: ${underlineText(phone.value)}`)],
            spacing: { after: 300 },
          }),
          new Paragraph({
            children: [new TextRun('REPRESENTANTE')],
            spacing: { after: 400 },
          }),
          new Paragraph({
            children: [new TextRun('________________________________________________')],
            spacing: { after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: font,
                text: `${underlineText('JAMILE EUGENIA GUZMAN RODRIGUEZ')}`,
              }),
            ],
            spacing: { after: 200 },
          }),
          new Paragraph({
            children: [new TextRun({ font: font, text: `C.C.  ${underlineText(ID.value)}` })],
            spacing: { after: 200 },
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((buffer) => {
    saveAs(buffer, 'Certificado_laboral.docx');
  });
};
