import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { changeLanguage, use } from 'i18next';

import { resources } from './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

use(initReactI18next).init({
  fallbackLng: 'es',
  interpolation: { escapeValue: false },
  lng: 'es',
  react: { useSuspense: false },
  resources,
});
changeLanguage('es');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
