import { useState, useContext } from 'react';
import SendIcon from '@mui/icons-material/Send';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useTranslation } from 'react-i18next';

import { MessageType } from '../../../../types';
import { Container, ButtonStyled, FaceStyled, PickerContainer } from './styles-input-bar';
import { DataContext } from '../../../../contexts';

type InputBarPropsType = {
  currentRoom: { name: string; id: string };
  socket: { emit: (arg0: string, arg1: MessageType) => void };
  handleAddMessageByRoom: (message: MessageType) => void;
  nameWriter: string;
};
export const InputBar = ({ currentRoom, socket, handleAddMessageByRoom }: InputBarPropsType) => {
  const [message, setMessage] = useState('');
  const [openEmoticons, setOpenEmoticons] = useState(false);
  const { user } = useContext(DataContext);
  const { t } = useTranslation();

  const sendMessages = () => {
    if (message.length === 0) {
      return;
    }
    socket.emit('send_message', {
      date: new Date(),
      message,
      recipientId: user.id.toString() === currentRoom.id ? '1' : currentRoom.id,
      room: currentRoom.id,
      senderId: user.id.toString(),
    });

    handleAddMessageByRoom({
      date: new Date(),
      message,
      recipientId: user.id.toString() === currentRoom.id ? '1' : currentRoom.id,
      room: currentRoom.id,
      senderId: user.id.toString(),
    });
    setMessage('');
  };

  const handlePickEmoji = (emonji: { native: string }) => {
    setMessage((prev) => `${prev}${emonji.native}`);
  };
  return (
    <Container ishidden={currentRoom.id === '0'}>
      <ButtonStyled onClick={() => setOpenEmoticons((prev) => !prev)} prev={false}>
        <FaceStyled
          openemoticons={`${openEmoticons}`}
          style={{
            height: '35px',
            width: '35px',
          }}
        />
      </ButtonStyled>
      <input
        placeholder={t('app.messages.type-message')}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            sendMessages();
          }
        }}
      />
      <ButtonStyled
        onClick={sendMessages}
        disabled={message.length === 0}
        prev={true}
        style={{ marginLeft: 10 }}
      >
        <SendIcon
          style={{
            color: message.length > 0 ? '#c8233b' : '#fff',
            height: '60px',
            width: '60px',
          }}
        />
      </ButtonStyled>
      {openEmoticons && (
        <PickerContainer>
          <Picker
            data={data}
            onEmojiSelect={(e: { native: string }) => handlePickEmoji(e)}
            dynamicWidth
            previewPosition={'none'}
          />
        </PickerContainer>
      )}
    </Container>
  );
};
