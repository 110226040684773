import { axiosInstance } from './proxy-base';
import { RequirementType } from '../types';

export const editRequirement = (body: RequirementType) => {
  return axiosInstance
    .put<{ requirement: RequirementType }>('/requirements/update', body)
    .then((response) => response?.data || ({} as RequirementType));
};

export const createRequirement = (body: RequirementType) => {
  return axiosInstance
    .post<{ requirement: RequirementType }>('/requirements/create', body)
    .then((response) => response.data);
};

export const getAllRequirements = (body?: {
  due_date_min?: Date;
  due_date_max?: Date;
  client_id?: number;
}) => {
  return axiosInstance
    .post<{ requirements: RequirementType[] }>('/requirements', body)
    .then((response) => response?.data || ([] as RequirementType[]));
};
