import styled from '@emotion/styled';
import { TextField, FormControl } from '@mui/material';

export const ContainerDocuments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;

  h2 {
    margin-bottom: 40px;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
`;

export const TextFieldStyled = styled(TextField)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  align-items: center;
  div.react-datepicker-wrapper {
    min-width: 260px;
    @media (max-width: 425px) {
      min-width: 200px;
    }
  }
`;

export const ContainerInputDate = styled(FormControl)`
  width: 100%;
  position: relative;
`;

export const ButtonCalendar = styled.button`
  position: absolute;
  top: 35%;
  right: 5%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const FormControlStyled = styled(FormControl)`
  min-width: 260px;
  @media (max-width: 425px) {
    min-width: 200px;
  }
`;
