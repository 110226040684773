import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Global, css } from '@emotion/react';

import {
  LogIn,
  RemindersPage,
  RequirementsPage,
  Admin,
  AppointmentBookings,
  Page404,
  Profile,
  Chat,
  Reports,
  Instructive,
  CertificatesPage,
  RestorePassword,
} from './pages';
import { UserLayout } from './layouts';
import { DataProvider } from './contexts';

const GlobalStyle = css`
  body {
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    font-weight: 600;

    .swal2-container {
      z-index: 20000;
    }

    div {
      background-color: transparent;
      &#root {
        min-height: 100vh;
        max-width: 100vw;
        overflow: hidden;
      }
    }
    .styleTitle {
      font-family: Montserrat;
    }
  }
`;

export default function App() {
  return (
    <DataProvider>
      <Global styles={GlobalStyle} />
      <BrowserRouter>
        <Routes>
          <Route element={<UserLayout />}>
            <Route path="/" element={<Navigate to="/recordatorios" />} />
            <Route path="/recordatorios" element={<RemindersPage />} />
            <Route path="/recordatorios/crear" element={<RemindersPage />} />
            <Route path="/requerimientos" element={<RequirementsPage />} />
            <Route path="/requerimientos/crear" element={<RequirementsPage />} />
            <Route path="/administrar" element={<Admin />} />
            <Route path="/agendamiento" element={<AppointmentBookings />} />
            <Route path="/perfil" element={<Profile />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/documentos" element={<Reports />} />
            <Route path="/instructivos" element={<Instructive />} />
            <Route path="/certificados" element={<CertificatesPage />} />
            <Route path="*" element={<Page404 />} />
          </Route>
          <Route path="/iniciar-sesion" element={<LogIn />} />
          <Route path="/restaurar-contraseña/:code" element={<RestorePassword />} />
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
}
