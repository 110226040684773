import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { createClient, editClient } from '../../../../../../services';
import { ErrorAlert } from '../../../../../../components';
import { ClientType } from '../../../../../../types';
import { FormControlStyled } from '../common-styles';

type HandleTextFieldChangeType = (fieldName: keyof ClientType, value: string | number) => void;

type PropsFormClientsType = {
  onClose: () => void;
  getClients: () => Promise<void>;
  title: string;
  action: 'create' | 'edit';
  prevClientInfo?: ClientType;
};

type ErrorType = {
  name: boolean;
  nit: boolean;
  representative_id: boolean;
  representative: boolean;
  verify_code: boolean;
  type: boolean;
  num_personality?: boolean;
};

export const FormClients = (props: PropsFormClientsType) => {
  const { t } = useTranslation();
  const {
    onClose,
    getClients,
    title,
    action,
    prevClientInfo = { calendar_color: '#3174ad' },
  } = props;

  const [client, setClient] = useState<ClientType>(prevClientInfo as ClientType);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as ErrorType);

  const HandleTextFieldChange: HandleTextFieldChangeType = (fieldName, value) => {
    setClient({ ...client, [fieldName]: value });
  };

  const verifyErrorContent = () => {
    let hasError = false;
    let errors = error;
    const paramsToVerify: string[] = [
      'name',
      'nit',
      'calendar_color',
      'representative',
      'representative_id',
      'verify_code',
      'type',
    ];
    for (const property of paramsToVerify) {
      if (!(property in client) || !client[property as keyof ClientType]) {
        hasError = true;
        errors = { ...errors, [property as keyof ErrorType]: true };
      }
    }

    if (hasError) {
      setError(errors);
    }
    return hasError;
  };
  const handleSendInfo = async () => {
    try {
      setLoading(true);
      const verifyIfHasError = verifyErrorContent();
      if (verifyIfHasError) {
        setLoading(false);

        return;
      }
      if (action === 'create') {
        await createClient(client);
      }
      if (action === 'edit') {
        await editClient(client);
      }
      setLoading(false);
      await getClients();
      onClose();
    } catch (error) {
      ErrorAlert(t(`errors.server-error`));
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
      <h2
        style={{
          color: '#e8e8e8',
          fontSize: '2rem',
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <TextField
        label={t('tables.clients-table.name')}
        value={client.name}
        onChange={(e) => HandleTextFieldChange('name', e.target.value)}
        error={error.name}
        variant="standard"
      />
      <TextField
        label={'Nit'}
        value={client.nit}
        onChange={(e) => HandleTextFieldChange('nit', parseInt(e.target.value))}
        error={error.nit}
        variant="standard"
        type="number"
      />
      <TextField
        label={t('tables.clients-table.verify-code')}
        value={client.verify_code}
        onChange={(e) => HandleTextFieldChange('verify_code', parseInt(e.target.value))}
        error={error.verify_code}
        variant="standard"
        type="number"
      />
      <TextField
        label={t('tables.clients-table.representative')}
        value={client.representative}
        onChange={(e) => HandleTextFieldChange('representative', e.target.value)}
        error={error.representative}
        variant="standard"
        type="text"
      />
      <TextField
        label={t('tables.clients-table.representative-id')}
        value={client.representative_id}
        onChange={(e) => HandleTextFieldChange('representative_id', parseInt(e.target.value))}
        error={error.representative_id}
        variant="standard"
        type="number"
      />
      <FormControlStyled variant="standard" sx={{ m: 1 }}>
        <InputLabel id="select-clients-form-label">{t('tables.clients-table.type')}</InputLabel>
        <Select
          labelId="select-clients-form-label"
          id="select-clients-form"
          error={error.type}
          value={client.type}
          onChange={(e) => HandleTextFieldChange('type', e.target.value)}
          label={t('tables.clients-table.type')}
        >
          <MenuItem value={'FUNDACION'}>{t('tables.clients-table.type-fundation')}</MenuItem>
          <MenuItem value={'EMPRESA'}>{t('tables.clients-table.type-enterprice')}</MenuItem>
          <MenuItem value={'IGLESIA'}>{t('tables.clients-table.type-church')}</MenuItem>
        </Select>
      </FormControlStyled>
      {client.type !== 'EMPRESA' && (
        <TextField
          label={t('tables.clients-table.num-personality')}
          value={client.num_personality}
          onChange={(e) => HandleTextFieldChange('num_personality', parseInt(e.target.value))}
          error={error.num_personality}
          variant="standard"
          type="number"
        />
      )}
      <div>
        <input
          id="colorEvent"
          type="color"
          value={client.calendar_color || '#3174ad'}
          onChange={(e) => HandleTextFieldChange('calendar_color', e.target.value)}
        />
        <label>{t('app.bookings.labels.color-event')}</label>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
              color: '#000',
            },
            bgcolor: '#e8e8e8',
            color: '#fff',
            marginTop: 5,
          }}
        >
          {t('app.default-translations.send')}
        </LoadingButton>
      </div>
    </div>
  );
};
