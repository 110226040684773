import { Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { editBooking } from '../../../../services';
import { BookingType } from '../../../../types';

type PropsDetailEventType = {
  event: BookingType;
  setModal: Dispatch<SetStateAction<string>>;
  bgcolor: string;
  handleGetBookings: () => void;
};

export const DetailEvent = (props: PropsDetailEventType) => {
  const {
    event: { start_time, title, end_time, in_person },
    setModal,
    bgcolor,
    handleGetBookings,
  } = props;

  const handleDelete = async () => {
    try {
      await editBooking({ ...props.event, deleted: true });
      handleGetBookings();
      setModal('');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        backgroundColor: bgcolor,
        minHeight: 150,
        minWidth: 200,
        position: 'relative',
      }}
    >
      <h1>{`${dayjs(start_time).format('DD/MM/YYYY')} - ${dayjs(end_time).format(
        'DD/MM/YYYY'
      )}`}</h1>
      <p style={{ color: in_person ? '#fff' : '#000' }}>{title}</p>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button onClick={handleDelete}>
          <DeleteIcon sx={{ color: '#000' }} />
        </Button>
      </div>
    </div>
  );
};
