import { forwardRef, Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { chPerm } from '../../../../helpers';
import { FiltersRequirementType } from '../../../../types';
import { ContainerInputDate, ButtonCalendar, ContainerDatePicker } from '../default-styles';
import { DataContext } from '../../../../contexts';
type PropsFiltersType = {
  filters: FiltersRequirementType;
  setFilters: Dispatch<SetStateAction<FiltersRequirementType>>;
};

type HandleTextFieldChangeType = (
  fieldName: keyof FiltersRequirementType,
  value: string | Date | number
) => void;

export const Filters = (props: PropsFiltersType) => {
  const { filters, setFilters } = props;
  const { t } = useTranslation();
  const { clients, user } = useContext(DataContext);

  const InputDate = forwardRef<
    HTMLInputElement,
    { value?: Date | null | undefined; onClick?: () => void; label: string }
  >(({ value = '', onClick, label }, ref) => (
    <ContainerInputDate ref={ref}>
      <TextField
        label={label}
        variant="standard"
        onClick={onClick}
        fullWidth
        value={value}
        sx={{
          input: { fontFamily: 'Montserrat', fontWeight: 500 },
          label: { fontFamily: 'Montserrat', fontWeight: 500 },
        }}
      />
      <ButtonCalendar onClick={onClick}>
        <CalendarMonthIcon />
      </ButtonCalendar>
    </ContainerInputDate>
  ));

  const handleChangePicker = (data: [Date, Date]) => {
    const [start, end] = data;
    handleChangeDate(start, 'due_date_min');
    handleChangeDate(end, 'due_date_max');
  };

  const handleChangeFilters: HandleTextFieldChangeType = (fieldName, value) => {
    setFilters({ ...filters, [fieldName]: value });
  };

  const handleChangeDate = (date: Date | null, param: 'due_date_max' | 'due_date_min') => {
    if (!date) {
      setFilters((prev) => {
        return { ...prev, [param]: '' };
      });
      return;
    }

    handleChangeFilters(param, date);
  };

  return (
    <>
      <ContainerDatePicker>
        <DatePicker
          startDate={filters?.due_date_min}
          endDate={filters?.due_date_max}
          onChange={handleChangePicker}
          shouldCloseOnSelect={false}
          selectsRange={true}
          customInput={<InputDate label={t('app.requirements.date-picker')} />}
          popperClassName="some-custom-class"
          popperPlacement="top-end"
          dateFormat="dd/MM/yy"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                rootBoundary: 'viewport',
                tether: false,
              },
            },
          ]}
        />
      </ContainerDatePicker>
      {chPerm([], user) && (
        <FormControl variant="standard" sx={{ m: 1, maxWidth: '230px', minWidth: '180px' }}>
          <InputLabel id="select-clients-label" sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}>
            {t('app.requirements.labels-create-requirement.client-name')}
          </InputLabel>
          <Select
            labelId="select-clients-label"
            id="select-clients"
            value={filters.client_id || ''}
            onChange={(e) => handleChangeFilters('client_id', e.target.value)}
            label={t('app.requirements.labels-create-requirement.client-name')}
          >
            {clients.map((client, index) => (
              <MenuItem
                key={index}
                value={client.id}
                sx={{ label: { fontFamily: 'Montserrat', fontWeight: 500 } }}
              >
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
