import styled from '@emotion/styled';

const margin = '10px';
const widthSidebar = '240px';
const heightNavbar = '54px';

export const ContentContainer = styled.div`
  height: calc(100% - ${heightNavbar});
  /* max-height: calc(100vh - ${heightNavbar}); */
  min-height: calc(100vh - ${heightNavbar});
  width: 100%;
  display: flex;
  background-color: #fff7f7;
  .safe-sidebar-size {
    display: none;
  }
  .container-content {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - ${heightNavbar} - 10px);
    margin: ${margin} ${margin} 0 0;
    margin-bottom: 0;
    background-color: #fff7f7;
  }
  @media (min-width: 1201px) {
    .safe-sidebar-size {
      min-width: ${widthSidebar};
      height: 100%;
      width: ${widthSidebar};
      display: block;
    }
    .container-content {
      width: calc(100vw - ${widthSidebar});
    }
  }
`;
