import { axiosInstance } from './proxy-base';
import { ClientType } from '../types';

export const editClient = (body: ClientType) => {
  return axiosInstance
    .put<{ client: ClientType }>('/clients/update', body)
    .then((response) => response?.data || ({} as ClientType));
};

export const createClient = (body: ClientType) => {
  return axiosInstance
    .post<{ client: ClientType }>('/clients/create', body)
    .then((response) => response.data);
};

export const getAllClients = (body?: {
  due_date_min?: Date;
  due_date_max?: Date;
  client_id?: number;
}) => {
  return axiosInstance
    .post<{ clients: ClientType[] }>('/clients', body)
    .then((response) => response?.data || ([] as ClientType[]));
};

export const getOneClient = (body?: { client_id?: number }) => {
  return axiosInstance
    .post<{ client: ClientType }>('/clients/get-client', body)
    .then((response) => response?.data || ({} as ClientType));
};
