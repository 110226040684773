import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { DataContext } from '../../../../../../contexts';
import { singup, editUser } from '../../../../../../services';
import { ErrorAlert } from '../../../../../../components';
import { AuthUserType } from '../../../../../../types';
import { FormControlStyled, TextFieldStyled } from './styles-form-users';

type HandleTextFieldChangeType = (fieldName: keyof AuthUserType, value: string | number) => void;

type PropsFormUsersType = {
  onClose: () => void;
  getUsers: () => Promise<void>;
  title: string;
  action: 'create' | 'edit';
  prevUserInfo?: AuthUserType;
};

type ErrorType = {
  name: boolean;
  email: boolean;
  role: boolean;
  password: boolean;
  client_id: boolean;
};

export const FormUsers = (props: PropsFormUsersType) => {
  const { clients } = useContext(DataContext);
  const { t } = useTranslation();
  const { onClose, getUsers, title, action, prevUserInfo = {} as AuthUserType } = props;

  const [user, setUser] = useState<AuthUserType>(prevUserInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as ErrorType);

  const ROLES = [
    { label: t('app.users.users-role.super-admin'), value: 'totalAccess' },
    { label: t('app.users.users-role.client'), value: 'client' },
  ];

  const HandleTextFieldChange: HandleTextFieldChangeType = (fieldName, value) => {
    setUser({ ...user, [fieldName]: value });
  };

  const verifyErrorContent = () => {
    let hasError = false;
    let errors = error;
    const paramsToVerify: string[] = ['name', 'email', 'role'];
    for (const property of paramsToVerify) {
      if (!(property in user) || !user[property as keyof AuthUserType]) {
        hasError = true;
        errors = { ...errors, [property as keyof ErrorType]: true };
      }
      if (user.role !== 'totalAccess' && !user.client_id) {
        hasError = true;
        errors = { ...errors, client_id: true };
      }
    }

    if (hasError) {
      setError(errors);
    }
    return hasError;
  };
  const handleSendInfo = async () => {
    try {
      setLoading(true);
      const verifyIfHasError = verifyErrorContent();
      if (verifyIfHasError) {
        setLoading(false);

        return;
      }
      if (action === 'create') {
        await singup(user);
      }
      if (action === 'edit') {
        await editUser(user);
      }
      setLoading(false);
      await getUsers();
      onClose();
    } catch (error) {
      ErrorAlert(t(`errors.server-error`));
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
      <h2
        style={{
          color: '#e8e8e8',
          fontSize: '2rem',
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <TextFieldStyled
        label={t('tables.users-table.name')}
        value={user.name}
        onChange={(e) => HandleTextFieldChange('name', e.target.value)}
        error={error.name}
        variant="standard"
      />
      <TextFieldStyled
        label={t('tables.users-table.email')}
        value={user.email}
        onChange={(e) => HandleTextFieldChange('email', e.target.value)}
        error={error.email}
        variant="standard"
      />
      <FormControlStyled variant="standard" sx={{ m: 1 }}>
        <InputLabel id="select-clients-form-label">{t('tables.users-table.client-id')}</InputLabel>
        <Select
          labelId="select-clients-form-label"
          id="select-clients-form"
          error={error.client_id}
          value={user.client_id}
          onChange={(e) => HandleTextFieldChange('client_id', e.target.value)}
          label={t('tables.users-table.client-id')}
        >
          {clients.map((client, index) => (
            <MenuItem key={index} value={client.id}>
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </FormControlStyled>
      <FormControlStyled variant="standard" sx={{ m: 1 }}>
        <InputLabel id="select-role-form-label">{t('tables.users-table.role')}</InputLabel>
        <Select
          labelId="select-role-form-label"
          id="select-role-form"
          error={error.role}
          value={user.role}
          onChange={(e) => HandleTextFieldChange('role', e.target.value)}
          label={t('tables.users-table.role')}
        >
          {ROLES.map((rol, index) => (
            <MenuItem key={index} value={rol.value}>
              {rol.label}
            </MenuItem>
          ))}
        </Select>
      </FormControlStyled>
      <TextFieldStyled
        label={t('tables.users-table.password')}
        value={user.password}
        onChange={(e) => HandleTextFieldChange('password', e.target.value)}
        error={error.password}
        variant="standard"
      />
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
              color: '#000',
            },
            bgcolor: '#e8e8e8',
            color: '#fff',
            marginTop: 5,
          }}
        >
          {t('app.default-translations.send')}
        </LoadingButton>
      </div>
    </div>
  );
};
