import styled from '@emotion/styled';

export const ItemContainer = styled.div`
  p {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    max-height: 100px;
  }
`;

export const MainText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #545454;
  font-weight: 400;
  word-wrap: break-word;
  span {
    font-weight: 600;
  }
`;
