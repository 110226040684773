import { useState, useEffect } from 'react';
import { Calendar, dayjsLocalizer, SlotInfo } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

import { ToastAlert } from '../../../../components';
import { createAvailability, editAvailability, getAllAvailabilities } from '../../../../services';
import { CalendarAvailabilityType } from '../../../../types';
import { deleteAvailability } from '../../../../services/availability-calendar';

const DnDCalendar = withDragAndDrop<CalendarAvailabilityType>(Calendar);

export const CalendarAvailability = () => {
  const { t, i18n } = useTranslation();
  const [availabilities, setAvailabilities] = useState<CalendarAvailabilityType[]>([]);

  dayjs.locale(i18n.language);
  const localizer = dayjsLocalizer(dayjs);

  useEffect(() => {
    const getAllCalendarAvailability = async () => {
      try {
        const res = await getAllAvailabilities();
        if (res?.availabilities) {
          const newData = res.availabilities.map((item) => {
            return {
              ...item,
              end_time: new Date(item.end_time),
              start_time: new Date(item.start_time),
            };
          });
          setAvailabilities(newData);
        }
      } catch (error) {
        console.log(error);
        setAvailabilities([]);
      }
    };
    getAllCalendarAvailability();
  }, []);

  const editEvent = async (availability: CalendarAvailabilityType) => {
    try {
      const res = await editAvailability(availability);
      if (res?.availability) {
        const { end_time, start_time } = res.availability;
        return {
          ...res.availability,
          end_time: new Date(end_time),
          start_time: new Date(start_time),
        };
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteEvent = async (id: number) => {
    try {
      const res = await deleteAvailability({ id: id });
      if (res?.success) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const createEvent = async (availability: CalendarAvailabilityType) => {
    try {
      const res = await createAvailability(availability);
      if (res?.availability) {
        const { end_time, start_time } = res.availability;
        return {
          ...res.availability,
          end_time: new Date(end_time),
          start_time: new Date(start_time),
        };
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const messages = {
    day: t('app.bookings.day'),
    next: t('app.bookings.next'),
    previous: t('app.bookings.previous'),
    today: t('app.bookings.today'),
    week: t('app.bookings.week'),
  };

  const handleSpaceSelected = async (event: SlotInfo) => {
    const { slots, end, start } = event;

    const isOverlap = availabilities.some((event) => {
      return (
        (start >= event.start_time && start < event.end_time) ||
        (end > event.start_time && end <= event.end_time) ||
        (start <= event.start_time && end >= event.end_time)
      );
    });

    if (isOverlap) {
      ToastAlert.fire({
        icon: 'error',
        title: t(`app.bookings.is-overlap`),
      });
      return;
    }
    const newEvent = { end_time: end, slots, start_time: start };
    const addEvent = await createEvent(newEvent as CalendarAvailabilityType);
    if (!addEvent) {
      ToastAlert.fire({
        icon: 'error',
        title: t(`app.bookings.error-safe`),
      });
      return;
    }

    setAvailabilities((prev) => [...prev, addEvent]);
  };

  const getSlotsByDates = (startDate: Date, endDate: Date) => {
    let slots = [];
    let current = new Date(startDate);

    while (current <= endDate) {
      slots.push(new Date(current));
      current.setMinutes(current.getMinutes() + 30);
    }

    return slots;
  };
  const handleOnEventDropAndResize: withDragAndDropProps<CalendarAvailabilityType>['onEventDrop'] =
    async (data) => {
      const {
        start,
        end,
        event: { id, user_id },
      } = data;
      const isOverlap = availabilities.some((event) => {
        return (
          event.id !== id &&
          ((start >= event.start_time && start < event.end_time) ||
            (end > event.start_time && end <= event.end_time) ||
            (start <= event.start_time && end >= event.end_time))
        );
      });

      if (isOverlap) {
        ToastAlert.fire({
          icon: 'error',
          title: t(`app.bookings.is-overlap`),
        });
        return;
      }

      const newStartDate = new Date(start);
      const newEndDate = new Date(end);

      const newEvent = {
        end_time: newEndDate,
        id: id,
        slots: getSlotsByDates(newStartDate, newEndDate),
        start_time: newStartDate,
        user_id: user_id,
      };

      const editedEvent = await editEvent(newEvent as CalendarAvailabilityType);

      if (!editedEvent) {
        ToastAlert.fire({
          customClass: 'styleTitle',
          icon: 'error',
          title: t(`app.bookings.error-safe`),
        });
        return;
      }

      const newEvents = availabilities.map((event) => {
        if (event.id === id) {
          return newEvent;
        }
        return event;
      });
      setAvailabilities(newEvents);
    };

  const handleShowToDelete = (event: CalendarAvailabilityType) => {
    Swal.fire({
      background: '#fff',
      cancelButtonText: t('app.bookings.cancel'),
      color: '#000000',
      confirmButtonColor: '#740634d8',
      confirmButtonText: t('app.bookings.confirm'),
      customClass: 'styleTitle',
      showCancelButton: true,
      title: t('app.bookings.delete-text'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteEvent(event.id);
        if (res) {
          setAvailabilities((prev) => {
            return prev.filter((item) => item.id !== event.id);
          });
          Swal.fire({
            background: '#fff',
            customClass: 'styleTitle',
            icon: 'success',
            text: t('app.bookings.done'),
          });
        }
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <DnDCalendar
        localizer={localizer}
        events={availabilities}
        startAccessor="start_time"
        endAccessor="end_time"
        messages={messages}
        onSelectSlot={(e) => handleSpaceSelected(e)}
        onSelectEvent={(e) => handleShowToDelete(e)}
        views={['day', 'week']}
        defaultView="day"
        selectable
        resizable
        onEventResize={handleOnEventDropAndResize}
        onEventDrop={handleOnEventDropAndResize}
      />
    </div>
  );
};
