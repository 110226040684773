import { useCallback, useEffect, useState, useContext, Fragment } from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { SwipeableDrawer } from '@mui/material';

import { addChat } from '../../../../services';
import {
  Container,
  RoomItem,
  Rounded,
  ContainerAddRoom,
  MobileContainerRoomItem,
  MobileContainerAddRoom,
} from './styles-chat-layout';
import { AuthUserType, ListChatType } from '../../../../types';
import { DataContext } from '../../../../contexts';

const colorAleatory = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgb(${r}, ${g}, ${b})`;
};

type ChatsLayoutType = {
  setCurrentRoom: (arg0: { name: string; id: string }) => void;
  currentRoom: { name: string; id: string };
  socket: { emit: (arg0: string, arg1: string) => void };
  lastMessage: { [key: string]: string };
  users: AuthUserType[];
  ableUsers: AuthUserType[];
  listRooms: ListChatType;
};

export const ChatsLayout = ({
  ableUsers,
  setCurrentRoom,
  currentRoom,
  socket,
  lastMessage,
  users,
  listRooms,
}: ChatsLayoutType) => {
  const [roomsList, setRoomList] = useState<{ value: string; color: string; idRoom: string }[]>([]);
  const [openModal, setopenModal] = useState<{ [key: string]: boolean }>({});
  const [selectedUser, setselectedUser] = useState<AuthUserType>({} as AuthUserType);
  const [open, setOpen] = useState(false);
  const { user } = useContext(DataContext);
  const { t } = useTranslation();

  const joinRoom = useCallback(
    (newRoom: { name: string; id: string }) => {
      socket.emit('join_room', newRoom.id);
    },
    [socket]
  );
  const handleGetRoomsList = useCallback(() => {
    if (listRooms?.chats && users.length > 0) {
      const rooms = listRooms.chats.split(',');
      const newRooms = rooms.map((item) => {
        return {
          color: colorAleatory(),
          idRoom: item,
          value: users.find((user) => user.id === parseInt(item))?.name || 'ERROR',
        };
      });
      setRoomList(newRooms);
    } else if (listRooms?.chats && listRooms?.chats.split(',')[0] === user.id.toString()) {
      const rooms = listRooms.chats.split(',');
      const newRooms = rooms.map((item) => {
        return {
          color: colorAleatory(),
          idRoom: item,
          value: 'FINANZAS INTEGRAS',
        };
      });
      setRoomList(newRooms);
    }
  }, [listRooms, users, user.id]);

  useEffect(() => {
    handleGetRoomsList();
  }, [handleGetRoomsList]);

  const handlePickRoom = (newRoom: { name: string; id: string }) => {
    if (newRoom.id === currentRoom.id) {
      return;
    }
    setCurrentRoom(newRoom);
    joinRoom(newRoom);
  };

  const handleOpen = () => setopenModal({ create: true });

  const handleClose = () => {
    setselectedUser({} as AuthUserType);
    setopenModal({ create: false });
  };

  const handleCreateRoom = async (newRoom: { name: string; id: string }) => {
    try {
      const roomValues = roomsList.map((item) => item.value);
      if (roomValues.includes(newRoom.name)) {
        handleClose();
        return;
      }

      setRoomList((prev) => [
        ...prev,
        { color: colorAleatory(), idRoom: newRoom.id, value: newRoom.name },
      ]);
      setselectedUser({} as AuthUserType);
      handleClose();

      const createDB = await addChat({ room_id: parseInt(newRoom.id), user_id: user.id });

      if (!createDB.success) {
        setRoomList((prev) => {
          return prev.filter((item) => item.value !== newRoom.name);
        });
      }
    } catch (error) {
      setRoomList((prev) => {
        return prev.filter((item) => item.value !== newRoom.name);
      });
    }
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen((prev) => !prev);
  };

  const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    left: '50%',
    overflowY: 'scroll',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  };

  return (
    <Container>
      <Box sx={{ display: { md: 'block', xs: 'none' } }}>
        {roomsList.map(({ value, color, idRoom }) => (
          <RoomItem
            key={value}
            selected={value === currentRoom.name}
            onClick={() => handlePickRoom({ id: idRoom, name: value })}
          >
            <Rounded style={{ backgroundColor: color }} />
            <div>
              <p className="name-room">{value}</p>
              <p className="last-message">
                {lastMessage[idRoom] && lastMessage[idRoom]?.length > 20
                  ? lastMessage[idRoom].slice(0, 20) + '...'
                  : lastMessage[idRoom]}
              </p>
            </div>
          </RoomItem>
        ))}
        <ContainerAddRoom
          onClick={handleOpen}
          style={{ display: ableUsers.length > 0 ? 'block' : 'none' }}
        >
          <GroupAddIcon style={{ fill: '#fff', height: '25px', width: '25px' }} />
        </ContainerAddRoom>
        {openModal?.create && (
          <Modal
            open
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {ableUsers.length > 0 ? (
                <List sx={{ bgcolor: 'background.paper', width: '100%' }} aria-label="contacts">
                  {ableUsers.map((item, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemButton
                        selected={item.name === selectedUser.name}
                        onClick={() => setselectedUser(item)}
                        sx={{ padding: '2px 0' }}
                      >
                        <ListItemText
                          inset
                          primary={item.name}
                          sx={{ padding: 0, textAlign: 'center' }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <div style={{ textAlign: 'center' }}>{t('app.messages.dont-able-users')}</div>
              )}
              <Button
                variant="contained"
                disabled={!selectedUser.name}
                onClick={() => {
                  handleCreateRoom({
                    id: selectedUser.id.toString(),
                    name: selectedUser.name || 'ERROR',
                  });
                }}
              >
                {t('app.messages.create-button')}
              </Button>
            </Box>
          </Modal>
        )}
      </Box>
      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        {(currentRoom.name === 'NO_ROOM' || open) && (
          <MobileContainerAddRoom>
            <ContainerAddRoom onClick={handleOpen}>
              <GroupAddIcon sx={{ fill: '#fff', height: '20px', width: '20px' }} />
            </ContainerAddRoom>
          </MobileContainerAddRoom>
        )}
        <div>
          <Fragment key={'top'}>
            <Button onClick={toggleDrawer()} sx={{ justifyContent: 'center', width: '100%' }}>
              {t('app.messages.messages')}
            </Button>
            <SwipeableDrawer
              anchor={'top'}
              open={open}
              onClose={toggleDrawer()}
              onOpen={toggleDrawer()}
              ModalProps={{
                BackdropProps: {
                  invisible: true,
                },
              }}
              sx={{
                '& .MuiDrawer-paper': {
                  top: 0,
                },
              }}
            >
              <Box
                sx={{ width: 'auto' }}
                role="presentation"
                onClick={toggleDrawer()}
                onKeyDown={toggleDrawer()}
              >
                <MobileContainerRoomItem>
                  {roomsList.map(({ value, color, idRoom }) => (
                    <RoomItem
                      key={value}
                      selected={value === currentRoom.name}
                      onClick={() => handlePickRoom({ id: idRoom, name: value })}
                    >
                      <Rounded style={{ backgroundColor: color }} />
                      <div>
                        <p className="name-room">{value}</p>
                        <p className="last-message">
                          {lastMessage[idRoom] && lastMessage[idRoom]?.length > 20
                            ? lastMessage[idRoom].slice(0, 20) + '...'
                            : lastMessage[idRoom]}
                        </p>
                      </div>
                    </RoomItem>
                  ))}
                </MobileContainerRoomItem>
              </Box>
            </SwipeableDrawer>
          </Fragment>
        </div>
      </Box>
    </Container>
  );
};
