import { axiosInstance } from './proxy-base';
import { CertificateType } from '../types';

export const createCertificateClient = (body: FormData) => {
  return axiosInstance
    .post<{ certificate: CertificateType }>('/certificates/create', body)
    .then((response) => response?.data || ({} as CertificateType));
};

export const getCertificates = (body: { client_id: number }) => {
  return axiosInstance
    .post<{ certificates: CertificateType[]; success: boolean }>('/certificates', body)
    .then(
      (response) => response?.data || ({ certificates: [] } as { certificates: CertificateType[] })
    );
};

export const getUrlCertificate = (body: { url: string }) => {
  return axiosInstance
    .post<{ url: string; success: boolean }>('/certificates/get-url', body)
    .then((response) => response?.data || ({} as { url: string }));
};
