import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';

import { UploadProfilePhoto } from '../../../../../../components';
import { ClientType, SetImageType } from '../../../../../../types';
import { ContainerBgInput, ContainerInput } from './styles-certificate';
import { createCertificateClient } from '../../../../../../services';

type PropsCertificateModalType = {
  client: ClientType;
  onClose: () => void;
  getClients: () => void;
};

type HandleTextFieldChangeType = (value: string) => void;

export const CertificateModal = (props: PropsCertificateModalType) => {
  const { t } = useTranslation();
  const [image, set_image] = useState<SetImageType>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState(false);

  const { getClients, onClose, client } = props;

  const HandleTextFieldChange: HandleTextFieldChangeType = (value) => {
    setName(value);
  };

  const handleSendInfo = async () => {
    if (!image) {
      return Swal.fire('Error');
    }
    if (!name) {
      setError(true);
    } else {
      setError(true);
    }
    setLoading(true);

    const data = new FormData();
    data.append('file', image);
    data.append('client_id', client.id.toString());
    data.append('title', name);

    try {
      await createCertificateClient(data);
      setLoading(false);
      onClose();
      getClients();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <ContainerInput>
        <ContainerBgInput>
          <UploadProfilePhoto
            name={'image'}
            labelDescription={t('app.certificates.archive')}
            imageFile={image}
            inputs={{ photo: '' }}
            set_image={set_image}
          />
        </ContainerBgInput>
      </ContainerInput>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ width: '60%' }}>
          <TextField
            label={t('tables.instructives-table.title')}
            value={name || ''}
            onChange={(e) => HandleTextFieldChange(e.target.value)}
            error={error}
            variant="standard"
            fullWidth
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          disabled={!image || !name}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
              color: '#000',
            },
            bgcolor: '#e8e8e8',
            color: '#fff',
            marginTop: 5,
          }}
        >
          {t('app.default-translations.send')}
        </LoadingButton>
      </div>
    </>
  );
};
