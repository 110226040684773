import React, { useEffect, useState, forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, TextField } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-datepicker';
import writtenNumber from 'written-number';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';

import {
  generateDocumentDonationCertificate,
  generateDocumentIncomes,
  generateDocumentWorkCertificate,
} from './templates';
import { ErrorAlert } from '../../components';
import { getOneClient } from '../../services';
import {
  TextFieldStyled,
  ContainerInputs,
  ContainerDocuments,
  ButtonCalendar,
  ContainerDatePicker,
  ContainerInputDate,
  FormControlStyled,
} from './styles-report';
import { DataContext } from '../../contexts';

type InputsType = {
  [key: string]: {
    hidden?: boolean;
    placeholder: string;
    value: string | Date;
    type?: string;
    max?: Date | null;
  };
};

export const Reports = () => {
  const [currentInputs, setCurrentInputs] = useState<InputsType>({});
  const [config, setConfig] = useState({ report: '', title: '' });
  const [notFound, setNotFound] = useState(false);
  const [inputssErrors, setInputssErrors] = useState<{ [key: string]: boolean }>({});

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const reportType = searchParams.get('report');
  const { user } = useContext(DataContext);
  const navigate = useNavigate();
  useEffect(() => {
    const inputsPerDocument: {
      [key: string]: {
        hidden?: boolean;
        name: string;
        placeholder: string;
        type?: string;
        max?: Date;
      }[];
    } = {
      donation: [
        { hidden: true, name: 'name', placeholder: t('reports.donation.name') },
        { hidden: true, name: 'NIT', placeholder: t('reports.donation.NIT'), type: 'number' },
        {
          hidden: true,
          name: 'num_legal_personality',
          placeholder: t('reports.donation.num-legal'),
        },
        { name: 'name_to', placeholder: t('reports.donation.name-to') },
        { name: 'ID_to', placeholder: t('reports.donation.ID-to'), type: 'number' },
        { name: 'quantity', placeholder: t('reports.donation.quantity'), type: 'number' },
        { name: 'concept', placeholder: t('reports.donation.concept') },
        { hidden: true, name: 'represent', placeholder: t('reports.work.represent') },
        {
          name: 'date',
          placeholder: t('reports.donation.date'),
          type: 'date',
        },
      ],
      incomes: [
        { hidden: true, name: 'name', placeholder: t('reports.incomes.name') },
        { hidden: true, name: 'ID', placeholder: t('reports.incomes.ID'), type: 'number' },
        {
          hidden: true,
          name: 'profesional_card',
          placeholder: t('reports.incomes.profesional_card'),
          type: 'number',
        },
        { name: 'name2', placeholder: t('reports.incomes.name') },
        { name: 'ID2', placeholder: t('reports.incomes.ID2'), type: 'number' },
        { name: 'amount_number', placeholder: t('reports.incomes.amount_number'), type: 'number' },
        { name: 'profession', placeholder: t('reports.incomes.profession') },
        { name: 'city', placeholder: t('reports.incomes.city') },
        { name: 'phone', placeholder: t('reports.incomes.phone'), type: 'number' },
        { name: 'date', placeholder: t('reports.incomes.date'), type: 'date' },
      ],
      work: [
        { name: 'place', placeholder: t('reports.work.place') },
        { hidden: true, name: 'name', placeholder: t('reports.work.name') },
        { hidden: true, name: 'ID', placeholder: t('reports.work.ID'), type: 'number' },
        { hidden: true, name: 'represent', placeholder: t('reports.work.represent') },
        { hidden: true, name: 'NIT', placeholder: t('reports.work.NIT'), type: 'number' },
        {
          hidden: true,
          name: 'NIT_number',
          placeholder: t('reports.work.NIT-number'),
          type: 'number',
        },
        { name: 'name_to', placeholder: t('reports.work.name-to') },
        { name: 'type_doc', placeholder: t('reports.work.type-doc'), type: 'select' },
        { name: 'ID_to', placeholder: t('reports.work.ID-to'), type: 'number' },
        { name: 'incomes', placeholder: t('reports.work.incomes'), type: 'number' },
        { name: 'concept', placeholder: t('reports.work.concept') },
        { name: 'phone', placeholder: t('reports.work.phone'), type: 'number' },
      ],
    };
    const handleChangeInputs = async () => {
      const key = reportType || '';
      setConfig({ report: key, title: t(`reports.${key}.title`) });
      let newInputs: InputsType = {};
      if (!(key in inputsPerDocument)) {
        setNotFound(true);
        return;
      } else {
        setNotFound(false);
      }
      for (let item of inputsPerDocument[key]) {
        newInputs[item.name] = {
          hidden: item.hidden || false,
          max: item.max || null,
          placeholder: item.placeholder,
          type: item.type,
          value: '',
        };
      }
      setCurrentInputs(newInputs);
    };
    handleChangeInputs();
  }, [t, reportType]);

  useEffect(() => {
    const handleGetNeedInfoPerModule = async () => {
      try {
        const includesInfo = ['work', 'incomes', 'donation'];
        if (!includesInfo.includes(reportType as string)) {
          return;
        }
        if (reportType === 'work') {
          const res = await getOneClient({ client_id: user.client_id });
          const client = res.client;
          setCurrentInputs((prev) => {
            return {
              ...prev,
              ID: { ...prev.ID, value: client.representative_id },
              name: { ...prev.name, value: client.name },
              NIT: { ...prev.NIT, value: client.nit.toString() },
              NIT_number: { ...prev.NIT_number, value: client.verify_code?.toString() },
              represent: { ...prev.represent, value: client.representative },
            };
          });
        } else if (reportType === 'incomes') {
          setCurrentInputs((prev) => {
            return {
              ...prev,
              ID: { ...prev.ID, value: '43.021.660' },
              name: { ...prev.name, value: 'JAMILE EUGENIA GUZMAN RODRIGUEZ' },
              profesional_card: { ...prev.profesional_card, value: '82076-T' },
            };
          });
        } else if (reportType === 'donation') {
          const res = await getOneClient({ client_id: user.client_id });
          const client = res.client;

          if (client.type === 'EMPRESA') {
            Swal.fire({
              background: '#fff',
              customClass: 'styleTitle',
              icon: 'error',
              text: 'NO DISPONIBLE',
            }).then(() => {
              navigate('/recordatorios');
            });
            return;
          }
          setCurrentInputs((prev) => {
            return {
              ...prev,
              name: { ...prev.name, value: client.name },
              NIT: {
                ...prev.NIT,
                value: client.nit.toString() + '-' + client.verify_code?.toString(),
              },
              num_legal_personality: {
                ...prev.num_legal_personality,
                value: client.num_personality || '',
              },
              represent: { ...prev.represent, value: client.representative },
            };
          });
        }
      } catch (error) {
        ErrorAlert('Ha ocurrido un problema para obtener la información adicional del archivo.');
      }
    };
    handleGetNeedInfoPerModule();
  }, [reportType, user.client_id, navigate]);

  const handleChangeValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 'OTRO') {
      setCurrentInputs((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            placeholder: t('reports.work.what-other'),
            type: 'input',
            value: value,
          },
        };
      });
    } else {
      setCurrentInputs((prev) => {
        return { ...prev, [name]: { ...prev[name], value: value } };
      });
    }

    setInputssErrors((prev) => {
      return { ...prev, [name]: false };
    });
  };

  const handleGenerate = () => {
    let valid = true;
    for (let key of Object.keys(currentInputs)) {
      if (currentInputs[key].value === '') {
        console.log(key);

        valid = false;
        setInputssErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    if (!valid) {
      ErrorAlert(t('reports.error'));
      return;
    }

    if (config.report === 'incomes') {
      const day = new Date(currentInputs?.date?.value).getDate().toString();
      const month = new Date(currentInputs?.date?.value).getMonth().toString();
      const year = new Date(currentInputs?.date?.value).getFullYear().toString();
      const dayString = t(`reports.numbers.${day}`);
      const monthString = t(`reports.months.${parseInt(month) + 1}`);
      const amountString = writtenNumber(
        parseFloat(currentInputs['amount_number'].value as string),
        {
          lang: 'es',
        }
      );

      const dataToSend = {
        ...currentInputs,
        amount_string: { placeholder: t('reports.incomes.amount_string'), value: amountString },
        day: { placeholder: t('reports.incomes.day'), value: day },
        dayString: { placeholder: t('reports.incomes.day'), value: dayString },
        month: { placeholder: t('reports.incomes.month'), value: monthString },
        year: { placeholder: t('reports.incomes.year'), value: year },
      };
      generateDocumentIncomes(dataToSend);
    } else if (config.report === 'work') {
      const dataToSend = {
        ...currentInputs,
      };
      generateDocumentWorkCertificate(dataToSend);
    } else if (config.report === 'donation') {
      const day = new Date(currentInputs?.date?.value).getDate().toString();
      const month = new Date(currentInputs?.date?.value).getMonth();
      const year = new Date(currentInputs?.date?.value).getFullYear().toString();
      const dayString = t(`reports.numbers.${day}`);
      const monthString = t(`reports.months.${month + 1}`);
      const quantityLetters = writtenNumber(parseFloat(currentInputs['quantity'].value as string), {
        lang: 'es',
      });

      const dataToSend = {
        ...currentInputs,
        date: {
          placeholder: t('reports.donation.date'),
          value: `${dayString} días de ${monthString} del año ${year}`,
        },
        quantity_string: {
          placeholder: t('reports.donation.quantity-string'),
          value: quantityLetters,
        },
        year: { placeholder: t('reports.donation.year'), value: year },
      };
      generateDocumentDonationCertificate(dataToSend);
    }
  };

  const InputDate = forwardRef<
    HTMLInputElement,
    { value: Date | string | null | undefined; onClick?: () => void; label: string; error: boolean }
  >(({ value = '', onClick, label, error }, ref) => {
    return (
      <ContainerInputDate ref={ref}>
        <TextField
          label={label}
          variant="standard"
          onClick={onClick}
          fullWidth
          value={value}
          error={error}
          sx={{
            input: { fontFamily: 'Montserrat', fontWeight: 500 },
            label: { fontFamily: 'Montserrat', fontWeight: 500 },
          }}
        />
        <ButtonCalendar onClick={onClick}>
          <CalendarMonthIcon />
        </ButtonCalendar>
      </ContainerInputDate>
    );
  });

  const handleChangeDatePickers = (value: Date, name: string) => {
    setCurrentInputs((prev) => {
      return { ...prev, [name]: { ...prev[name], value: new Date(value) } };
    });

    setInputssErrors((prev) => {
      return { ...prev, [name]: false };
    });
  };
  return (
    <ContainerDocuments>
      {notFound ? (
        <div>Not found</div>
      ) : (
        <>
          <h2 style={{ fontFamily: 'Montserrat', fontWeight: 500 }}>{config.title}</h2>
          <ContainerInputs>
            {Object.keys(currentInputs).map((item, index) => {
              if (currentInputs[item].hidden) {
                return '';
              }
              return (
                <div key={index}>
                  {currentInputs[item]?.type === 'date' ? (
                    <ContainerDatePicker>
                      <DatePicker
                        selected={
                          currentInputs[item]?.value ? new Date(currentInputs[item].value) : null
                        }
                        showYearDropdown
                        maxDate={currentInputs[item].max || new Date(2029, 11, 31)}
                        minDate={new Date(2020, 11, 31)}
                        dateFormat="dd/MM/yy"
                        onChange={(date) => {
                          if (date) {
                            handleChangeDatePickers(date, item);
                          }
                        }}
                        shouldCloseOnSelect={false}
                        customInput={
                          <InputDate
                            value={new Date(currentInputs[item].value)}
                            label={currentInputs[item].placeholder}
                            error={inputssErrors[item]}
                          />
                        }
                        popperClassName="some-custom-class"
                        popperPlacement="top-end"
                        popperModifiers={[
                          {
                            name: 'offset',
                            options: {
                              offset: [5, 10],
                            },
                          },
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                              rootBoundary: 'viewport',
                              tether: false,
                            },
                          },
                        ]}
                      />
                    </ContainerDatePicker>
                  ) : currentInputs[item]?.type === 'year' ? (
                    <ContainerDatePicker>
                      <DatePicker
                        selected={
                          currentInputs[item]?.value ? new Date(currentInputs[item].value) : null
                        }
                        showYearPicker
                        maxDate={currentInputs[item].max || new Date(2029, 11, 31)}
                        minDate={new Date(2020, 11, 31)}
                        onChange={(date) => {
                          if (date) {
                            handleChangeDatePickers(date, item);
                          }
                        }}
                        shouldCloseOnSelect={false}
                        customInput={
                          <InputDate
                            value={new Date(currentInputs[item].value)}
                            label={currentInputs[item].placeholder}
                            error={inputssErrors[item]}
                          />
                        }
                        popperClassName="some-custom-class"
                        popperPlacement="top-end"
                        popperModifiers={[
                          {
                            name: 'offset',
                            options: {
                              offset: [5, 10],
                            },
                          },
                          {
                            name: 'preventOverflow',
                            options: {
                              altAxis: true,
                              rootBoundary: 'viewport',
                              tether: false,
                            },
                          },
                        ]}
                      />
                    </ContainerDatePicker>
                  ) : currentInputs[item]?.type === 'select' ? (
                    <FormControlStyled variant="standard" sx={{ m: 1 }} error={inputssErrors[item]}>
                      <InputLabel
                        id="select-clients-form-label"
                        sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}
                      >
                        {currentInputs[item].placeholder}
                      </InputLabel>
                      <Select
                        labelId="select-clients-form-label"
                        id="select-clients-form"
                        name={item}
                        error={inputssErrors[item]}
                        value={currentInputs[item].value || ''}
                        fullWidth
                        onChange={(e) =>
                          handleChangeValueInput(e as React.ChangeEvent<HTMLInputElement>)
                        }
                        label={currentInputs[item].placeholder}
                      >
                        <MenuItem value="Cédula de Ciudadania">Cédula de Ciudadania</MenuItem>
                        <MenuItem value="Cédula de Extranjería">Cédula de Extranjería</MenuItem>
                        <MenuItem value="Pasaporte">Pasaporte</MenuItem>
                        <MenuItem value="OTRO">Otro</MenuItem>
                      </Select>
                    </FormControlStyled>
                  ) : (
                    <TextFieldStyled
                      label={currentInputs[item].placeholder}
                      variant="standard"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValueInput(e)
                      }
                      autoComplete="off"
                      fullWidth
                      value={currentInputs[item].value}
                      name={item}
                      type={currentInputs[item].type || 'text'}
                      error={inputssErrors[item]}
                      sx={{
                        input: { fontFamily: 'Montserrat', fontWeight: 500 },
                        label: { fontFamily: 'Montserrat', fontWeight: 500 },
                      }}
                    />
                  )}
                </div>
              );
            })}
          </ContainerInputs>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              size="large"
              onClick={() => handleGenerate()}
              sx={{
                '&:hover': {
                  bgcolor: '#740634d8',
                  color: '#000',
                },
                bgcolor: '#e8e8e8',
                color: '#fff',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                marginTop: 5,
              }}
            >
              {t('reports.generate')}
            </Button>
          </div>
        </>
      )}
    </ContainerDocuments>
  );
};
