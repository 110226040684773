import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';

import { createInstructive, editInstructive } from '../../../../../../services';
import { ErrorAlert } from '../../../../../../components';
import { InstructiveType } from '../../../../../../types';

type HandleTextFieldChangeType = (fieldName: keyof InstructiveType, value: string | number) => void;

type PropsFormInstructivesType = {
  onClose: () => void;
  getInstructives: () => Promise<void>;
  title: string;
  action: 'create' | 'edit';
  prevInstructiveInfo?: InstructiveType;
};

type ErrorType = {
  title: boolean;
  description: boolean;
  url: boolean;
  category: boolean;
};

export const FormInstructives = (props: PropsFormInstructivesType) => {
  const { t } = useTranslation();
  const { onClose, getInstructives, title, action, prevInstructiveInfo } = props;

  const [instructive, setInstructive] = useState<InstructiveType>(
    (prevInstructiveInfo || {}) as InstructiveType
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as ErrorType);

  const HandleTextFieldChange: HandleTextFieldChangeType = (fieldName, value) => {
    setInstructive({ ...instructive, [fieldName]: value });
  };

  const verifyErrorContent = () => {
    let hasError = false;
    let errors = error;
    const paramsToVerify: string[] = ['title', 'description', 'url'];
    for (const property of paramsToVerify) {
      if (!(property in instructive) || !instructive[property as keyof InstructiveType]) {
        hasError = true;
        errors = { ...errors, [property as keyof ErrorType]: true };
      }
    }

    if (hasError) {
      setError(errors);
    }
    return hasError;
  };
  const handleSendInfo = async () => {
    try {
      setLoading(true);
      const verifyIfHasError = verifyErrorContent();
      if (verifyIfHasError) {
        setLoading(false);

        return;
      }
      if (action === 'create') {
        await createInstructive(instructive);
      }
      if (action === 'edit') {
        await editInstructive(instructive);
      }
      setLoading(false);
      await getInstructives();
      onClose();
    } catch (error) {
      ErrorAlert(t(`errors.server-error`));
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
      <h2
        style={{
          color: '#e8e8e8',
          fontSize: '2rem',
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <TextField
        label={t('tables.instructives-table.title')}
        value={instructive.title || ''}
        onChange={(e) => HandleTextFieldChange('title', e.target.value)}
        error={error.title}
        variant="standard"
      />
      <TextField
        label={t('tables.instructives-table.category')}
        value={instructive.category || ''}
        onChange={(e) => HandleTextFieldChange('category', e.target.value.toUpperCase())}
        error={error.category}
        variant="standard"
      />
      <TextField
        label={'Url'}
        value={instructive.url || ''}
        onChange={(e) => HandleTextFieldChange('url', e.target.value)}
        error={error.url}
        variant="standard"
      />
      <TextField
        label={t('tables.instructives-table.description')}
        value={instructive.description || ''}
        onChange={(e) => HandleTextFieldChange('description', e.target.value)}
        error={error.description}
        variant="standard"
        sx={{ width: '80%' }}
      />
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton
          size="large"
          onClick={handleSendInfo}
          loading={loading}
          sx={{
            '&:hover': {
              bgcolor: '#740634d8',
              color: '#000',
            },
            bgcolor: '#e8e8e8',
            color: '#fff',
            marginTop: 5,
          }}
        >
          {t('app.default-translations.send')}
        </LoadingButton>
      </div>
    </div>
  );
};
