import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { getAllinstructives } from '../../../../../../services';
import { AgGridTable } from '../../../../../../components';
import { GetInstructivessColumns, FormInstructives } from '../index';
import { InstructiveType } from '../../../../../../types';
import { BarFiltersContainer, style } from '../common-styles';

export const InstructiveInterface = () => {
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState<InstructiveType[]>([]);
  const [instructives, setInstructives] = useState<InstructiveType[]>([]);

  const [modal, setModal] = useState('');

  const handleGetInstructivesData = async () => {
    try {
      const res = await getAllinstructives();
      setInstructives(res.instructives);
    } catch (error) {
      console.log(error);
      setInstructives([]);
    }
  };

  useEffect(() => {
    handleGetInstructivesData();
  }, []);

  const hadleChangeSelectedRows = (selectedRows: InstructiveType[]) => {
    setSelectedRow(selectedRows);
  };
  const handleOpenModalCreate = () => {
    setModal('create-client');
  };

  const handleOpenModaledit = () => {
    setModal('edit-client');
  };

  const handleClose = () => {
    setModal('');
  };
  return (
    <>
      <BarFiltersContainer>
        <Button
          endIcon={<AddIcon sx={{ color: '#e8e8e8' }} />}
          size="large"
          onClick={handleOpenModalCreate}
          sx={{
            '&:hover': {
              bgcolor: '#a7a4a4',
              span: {
                transform: 'scale(1.3)',
              },
            },
            bgcolor: '#d3d2d2',
            borderRadius: '20px',
            color: '#000',
            height: 'fit-content',
            marginTop: 2,
            padding: '8px 12px',
            textTransform: 'none',
          }}
        >
          {t('app.default-translations.create')}
        </Button>
        <Button
          endIcon={<ModeEditIcon sx={{ color: '#e8e8e8' }} />}
          size="medium"
          onClick={handleOpenModaledit}
          disabled={selectedRow.length === 0}
          sx={{
            '&:hover': {
              bgcolor: '#a7a4a4',
              span: {
                transform: 'scale(1.3)',
              },
            },
            bgcolor: '#d3d2d2',
            borderRadius: '20px',
            color: '#000',
            height: 'fit-content',
            marginTop: 2,
            padding: '8px 12px',
            textTransform: 'none',
          }}
        >
          {t('app.default-translations.edit')}
        </Button>
      </BarFiltersContainer>

      <AgGridTable
        columnParameters={GetInstructivessColumns()}
        data={instructives}
        handleClick={hadleChangeSelectedRows}
      />
      <Modal
        open={modal === 'edit-client'}
        onClose={handleClose}
        aria-labelledby="modal-edit-user"
        aria-describedby="this is a modal used to modify the content of a user"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: '60%',
          }}
        >
          <FormInstructives
            title={t('app.default-translations.edit')}
            action="edit"
            prevInstructiveInfo={selectedRow[0] as InstructiveType}
            onClose={handleClose}
            getInstructives={async () => {
              handleGetInstructivesData();
              setSelectedRow([]);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'create-client'}
        onClose={handleClose}
        aria-labelledby="modal-create-client"
        aria-describedby="this is a modal used to create a client"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            width: '60%',
          }}
        >
          <FormInstructives
            title={t('app.default-translations.edit')}
            action="create"
            onClose={handleClose}
            getInstructives={async () => {
              handleGetInstructivesData();
              setSelectedRow([]);
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
