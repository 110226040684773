import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import { LoadingButton } from '@mui/lab';

import { ProfileContainer } from './styles-profile'
import { getOneUser, getOneClient, getCurrentUser, editUser } from '../../services'
import { Loader } from '../../components'
import { AuthUserType, ClientType } from '../../types'

type HandleTextFieldChangeType = (
    value: string
  ) => void;

export const Profile = () => {
    const [userData, setUserData] = useState<AuthUserType>({} as AuthUserType);
    const [newName, setNewName] = useState('');
    const [clientData, setClientData] = useState<ClientType>({} as ClientType);
    const [loading, setLoading] = useState({ client: false, user:false,});
    const [error, setError] = useState('');
    const [changeName, setChangeName] = useState(false)

    const { t } = useTranslation()


    const handleGetUserInfo = async (email: string) => {
      try {
        const response = await getOneUser({email: email})   
         if (!response.user){
          setError('No se encontró un usuario')
         }else {
           setUserData({...response.user})
           setNewName(response.user.name as string)
         }    
         return true        
      } catch (error) {
        console.log(error)
        return true           
      }
    }

    const handleGetClientInfo = async (client_id: number) => {
      try {
        setLoading(prev => {return {...prev, client: true}})                   
        const response = await getOneClient({client_id: client_id})        
         if (!response.client){
          setError('No se encontró el Cliente asociado.')
         }else {
           setClientData({...response.client})
         }         
        return true           

      } catch (error) {
        console.log(error)
        return true           

      }
    }

    useEffect(()=>{
      const  user = getCurrentUser()
      setLoading({client: true,user: true})   
      const handleGetInfo= async () =>{
        await handleGetUserInfo(user.email)        
        await handleGetClientInfo(user.client_id)
        setLoading({client: false, user: false})
      }
      handleGetInfo()
    }, [setUserData])
    
    const HandleTextFieldChange: HandleTextFieldChangeType = (value) => {
      setNewName(value)
    };     
      
    const handleSend = async () =>{
      try {
        setLoading({client: false, user: true})
        const res = await editUser({...userData, name: newName})
        if (res){
          setLoading({client: false, user: false})
        }
      } catch (error) {
        console.log(error);
        setLoading({client: false, user: false})
      }
    }

    const handleEdit= () =>{
      setNewName(userData.name as string )
      setChangeName(prev => !prev)
    }    

    if(error){
      return <p>{error}</p>
    }
  return (
    <ProfileContainer>
      {!loading.client && !loading.user ? (
        <>
      <div className='container'>
        <AccountCircleIcon sx={{color: '#43494f', height:{ md: 250, xs: 150}, width: {md:250, xs: 150}}} />
      </div>
      <div className="container inputs">
        <h4>{t('app.profile.user-info')}</h4>
        <FormControl sx={{ maxWidth: { sm: '60%', xs: '100%' } }} fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">{t('app.profile.name')}</InputLabel>
          <Input
            id="standard-multiline-flexible"
            value={changeName ? newName : userData.name}
            onChange={(e) => HandleTextFieldChange(e.target.value)}
            disabled={!changeName}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleEdit}
                  edge="end"
                >
                  {changeName ? <DoDisturbOnIcon/> : <EditIcon/>}
                </IconButton>
              </InputAdornment>
            }
          />
            
        </FormControl>
        <TextField
          id="standard-multiline-flexible"
          label={t('app.profile.email')}
          variant="standard"
          value={userData.email}
          fullWidth
          disabled
          sx={{ maxWidth: { sm: '60%', xs: '100%' } }}
        />
        <h4>{t('app.profile.client-info')}</h4>
        <TextField
          id="standard-multiline-flexible"
          label={t('app.profile.name')}
          variant="standard"
          value={clientData.name}
          fullWidth
          disabled
          sx={{ maxWidth: { sm: '60%', xs: '100%' } }}
        />
        <TextField
          id="standard-multiline-flexible"
          label={'NIT'}
          variant="standard"
          value={clientData.nit}
          fullWidth
          disabled
          sx={{ maxWidth: { sm: '60%', xs: '100%' } }}
        />
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>  
            <LoadingButton
              size="large"
              onClick={handleSend}
              loading={loading.user}
              disabled={!changeName || !newName}
              sx={{
                '&:hover': {
                  bgcolor: '#740634d8',
                },
                bgcolor: changeName && newName ? '#42494f' : '#c8c8c88e',
                color: '#fff',
                marginTop: 5,
              }}
            >
              {t('app.profile.send')}
            </LoadingButton>


        </div>
      </div>
        
        </>
      ) :  
      <Loader/>}

    </ProfileContainer>
  )
}
