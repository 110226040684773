import styled from '@emotion/styled';

export const InstructivesContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  overflow-x: hidden;
  flex-direction: column;
`;

export const InstructiveItem = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  max-width: 220px;

  .cont-screen-video {
    background-color: #14161c;
    border-radius: 20px;
    width: 220px;
    height: 140px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }
  .cont-content {
    box-sizing: border-box;
    padding: 0 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    h5 {
      width: 90%;
      margin: 0 0 5px;
      font-weight: 600;
      font-size: 18px;
      text-align: start;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #8a8a8a;
      width: 90%;
      text-align: strat;
    }
  }
`;

export const CategoryContainer = styled.div`
  .title-cont {
    background-color: #264c5b;
    border-radius: 0 14px 14px 0;
    opacity: 85%;
    padding: 10px;
    min-width: 270px;
    max-width: 300px;
    margin-bottom: 15px;
    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: 800;
      margin: 0 0 0 10px;
    }
  }
  .cont-arrow {
    position: fixed;
    top: 40px;
    transition: transform 0.5s ease-in-out;
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
    svg {
      fill: #fff;
    }

    &:hover {
      background-color: #264c5b;
    }
  }
  .contain-items {
    margin-left: 40px;
    display: flex;
    gap: 12px;
    transition: transform 0.5s ease-in-out;
  }
`;

export const VideoContainer = styled.div`
  max-width: 80%;
  width: 80%;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #000;
  border-radius: 15px;
  border: 2px solid #fff;
`;

export const DetailVideoCont = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 84px);

  .img-cont {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      opacity: 0.5;
      width: 90%;
      height: 90%;
      border-radius: 8px;
    }
    .cont-logo {
      position: relative;
      height: 100%;
      .right {
        position: absolute;
        bottom: 8%;
        left: -80px;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .left {
        position: absolute;
        top: 8%;
        right: -80px;
        cursor: pointer;
        z-index: 3;
      }
    }
  }
  .content-cont {
    width: 90%;
    height: 90%;
    max-height: 90%;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .description {
      margin-top: 10px;
      width: 80%;
      padding: 0 40px;
      .video-title {
        font-size: 24px;
        color: #264c5b;
        font-weight: 800;
        margin: 0;
        width: 70%;
      }

      .video-description {
        font-size: 16px;
        color: #264c5b;
        font-weight: 500;
        margin: 10px 0 0;
        width: 80%;
      }
    }
  }
`;
