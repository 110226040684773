import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

export const MenuItemComp = styled(MenuItem)`
  z-index: 2;
  &.MuiButtonBase-root.MuiMenuItem-root:hover {
    background-color: ${(props) => props.selected && '#264C5B'};
    span {
      color: ${(props) => (props.selected ? '#fff' : 'black')};
      font-family: Montserrat;
      font-weight: 500;
    }
  }
  &.MuiButtonBase-root.MuiMenuItem-root {
    width: 95%;
    max-height: 40px;
    padding: 0px 5px;
    border-radius: 16px;
  }
  &.Mui-selected {
    background-color: ${(props) => props.selected && '#264C5B'};
    span {
      color: ${(props) => (props.selected ? '#fff' : 'black')};
    }
  }
  div.MuiListItemIcon-root {
    min-width: 20px;
  }
  div.icon {
    svg {
      fill: ${(props) => (props.selected ? '#e0e0e0' : '#4b4b4b')};
    }
  }
  div {
    svg {
      fill: #4b4b4b;
    }
    span {
      color: #4b4b4b;
      font-weight: 400;
    }
  }

  &:hover {
    div {
      svg {
        fill: ${(props) => (props.selected ? '#e0e0e0' : '#4b4b4b')};
      }
      span {
        fill: ${(props) => (props.selected ? '#e0e0e0' : '#4b4b4b')};
      }
    }
  }
`;

export const ListItemTextComp = styled(ListItemText)`
  MuiListItemIcon-root,
  span {
    color: #4b4b4b;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: Montserrat;
  }

  div.container-reminder {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #817b7b;
    font-size: 14px;
    color: #fff;
  }
`;
export const ListItemIconComp = styled(ListItemIcon)`
  background-color: transparent;
  &.MuiListItemIcon-root {
    padding: 0.25rem;
    margin-right: 8px;
    min-width: 25px;
  }
`;
export const Containerbg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  div {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
