import { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';
import { LoadingButton } from '@mui/lab';

import { ReminderItem, Filters, DetailReminder, FormComponent } from './components';
import { DataContext } from '../../contexts';
import { editReminder, getAllReminders } from '../../services';
import { ErrorAlert, Loader } from '../../components';
import { chPerm } from '../../helpers';
import { ReminderType, FiltersRemindersType } from '../../types';
import { ContainerRemindersList, BarFiltersContainer } from './styles-reminders';

export const RemindersPage = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FiltersRemindersType>({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState('');
  const [isDraggingOver, setIsDraggingOver] = useState(0);
  const [reminderSelected, setReminderSelected] = useState<ReminderType>({} as ReminderType);

  const { reminders, setReminders, user, clients, loadingData } = useContext(DataContext);
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const idReminder = queryParams.get('id');
  const EditReminderParam = queryParams.get('edit');

  const handleGetDetailReminder = useCallback(
    (id: string) => {
      const reminder = reminders.find((item) => item.id === parseInt(id));
      if (reminder) {
        setReminderSelected(reminder);
        return reminder;
      }
      return false;
    },
    [reminders]
  );

  const handlegetAllReminders = async (filters?: FiltersRemindersType) => {
    try {
      setLoading(true);
      let newFilters = { ...filters };
      if (!newFilters?.due_date_max && newFilters?.due_date_min) {
        newFilters = { ...newFilters, due_date_max: newFilters.due_date_min };
      }
      const res = await getAllReminders(newFilters);
      setReminders(res.reminders);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setReminders([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idReminder) {
      const getReminder = handleGetDetailReminder(idReminder);
      if (getReminder) {
        if (EditReminderParam) {
          setModal('edit');
        } else {
          setModal('detail');
        }
      }
    }

    if (pathname.includes('crear')) {
      setModal('create');
    }
  }, [idReminder, pathname, handleGetDetailReminder, EditReminderParam]);

  const filterReminders = (statusType: string) =>
    reminders?.filter((item) => item?.status?.toLowerCase() === statusType?.toLowerCase());

  const remindersInProcess = filterReminders('in process');
  const remindersPending = filterReminders('pending');
  const remindersCompleted = filterReminders('completed');

  const handleEditReminder = async (reminder: ReminderType) => {
    try {
      await editReminder(reminder);
    } catch (error) {
      console.error(error);
      ErrorAlert(t(`errors.server-error`));
      handlegetAllReminders(filters);
    }
  };

  const handleDrop = (e: DragEvent, newStatus: string) => {
    const itemId = e.dataTransfer.getData('itemId');
    const newState = reminders.map((reminder) => {
      if (reminder?.id === parseInt(itemId)) {
        const newReminder = { ...reminder, status: newStatus };
        handleEditReminder(newReminder);
        return newReminder;
      }
      return reminder;
    });
    setReminders(newState);
  };

  const handleClose = () => {
    setModal('');
    navigate('/recordatorios');
  };
  const bgColorReminder = (reminder: ReminderType) => {
    return clients.find((client) => client.id === reminder.client_id)?.calendar_color || '#dfdddc';
  };

  const handleRestoreFilters = () => {
    setLoading(true);
    setFilters({});
    handlegetAllReminders();
  };

  const style = {
    bgcolor: '#fff',
    border: '1px solid #9c9a9a',
    borderRadius: '20px',
    boxShadow: 24,
    left: '50%',
    overFlowY: 'scroll',
    p: { md: 4, xs: 0 },
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ width: '100%' }}>
      <BarFiltersContainer>
        {chPerm([], user) && (
          <Button
            endIcon={<AddIcon sx={{ color: '#e8e8e8' }} />}
            size="large"
            onClick={() => navigate('/recordatorios/crear')}
            sx={{
              '&:hover': {
                bgcolor: '#a7a4a4',
                span: {
                  transform: 'scale(1.3)',
                },
              },
              bgcolor: '#d3d2d2',
              borderRadius: '20px',
              color: '#000',
              fontFamily: 'Montserrat',
              fontWeight: 500,
              height: 'fit-content',
              marginTop: 2,
              padding: '8px 12px',
              textTransform: 'none',
            }}
          >
            {t('app.reminders.create')}
          </Button>
        )}

        <Filters filters={filters} setFilters={setFilters} />
        <div style={{ alignItems: 'center', display: 'flex', gap: 5 }}>
          <IconButton aria-label="restore" onClick={handleRestoreFilters} sx={{ marginTop: 2 }}>
            <RestoreIcon />
          </IconButton>
          <LoadingButton
            loading={loading}
            sx={{
              '&:hover': {
                bgcolor: '#a7a4a4',
              },
              bgcolor: '#43494f',
              fontFamily: 'Montserrat',
              fontWeight: 500,
              marginTop: 2,
            }}
            variant="contained"
            onClick={() => {
              setLoading(true);
              handlegetAllReminders(filters);
            }}
          >
            {t('app.reminders.search')}
          </LoadingButton>
        </div>
      </BarFiltersContainer>
      {!loading && !loadingData ? (
        <ContainerRemindersList>
          <div
            className={`container-reminders-items ${isDraggingOver === 1 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(1);
            }}
            onDrop={(e) => {
              handleDrop(e, 'pending');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.reminders.reminders-status.pending')} (${remindersPending.length})`}</h3>
            {remindersPending.map((reminder, index) => (
              <ReminderItem key={index} reminder={reminder} color={bgColorReminder(reminder)} />
            ))}
          </div>
          <div
            className={`container-reminders-items ${isDraggingOver === 2 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(2);
            }}
            onDrop={(e) => {
              handleDrop(e, 'in process');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.reminders.reminders-status.in-process')} (${
              remindersInProcess.length
            })`}</h3>
            {remindersInProcess.map((reminder, index) => (
              <ReminderItem key={index} reminder={reminder} color={bgColorReminder(reminder)} />
            ))}
          </div>
          <div
            className={`container-reminders-items ${isDraggingOver === 3 ? 'active' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDraggingOver(3);
            }}
            onDrop={(e) => {
              handleDrop(e, 'completed');
              setIsDraggingOver(0);
            }}
            onDragLeave={() => setIsDraggingOver(0)}
          >
            <h3>{`${t('app.reminders.reminders-status.completed')} (${
              remindersCompleted.length
            })`}</h3>
            {remindersCompleted.map((reminder, index) => (
              <ReminderItem key={index} reminder={reminder} color={bgColorReminder(reminder)} />
            ))}
          </div>
        </ContainerRemindersList>
      ) : (
        <Loader />
      )}
      <Modal
        open={modal === 'detail'}
        onClose={handleClose}
        aria-labelledby="modal-detai"
        aria-describedby="this is a modal used to see all the content of a reminder"
      >
        <Box sx={{ ...style, borderColor: bgColorReminder(reminderSelected) }}>
          <DetailReminder reminder={reminderSelected} />
        </Box>
      </Modal>
      <Modal
        open={modal === 'create'}
        onClose={handleClose}
        aria-labelledby="modal-create-reminder"
        aria-describedby="this is a modal used to add the content to a new reminder"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box sx={{ ...style, width: { md: '60%', xs: '90%' } }}>
          <FormComponent
            title={t('app.reminders.create')}
            action="create"
            onClose={handleClose}
            getReminders={async () => {
              handlegetAllReminders(filters);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={modal === 'edit'}
        onClose={handleClose}
        aria-labelledby="modal-edit-reminder"
        aria-describedby="this is a modal used to modify the content of a reminder"
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            top: '50%',
            transform: `translate(-50%, -40%)`,
            width: { md: '60%', xs: '90%' },
          }}
        >
          <FormComponent
            title={t('app.reminders.edit')}
            action="edit"
            prevReminderInfo={reminderSelected}
            onClose={handleClose}
            getReminders={async () => {
              handlegetAllReminders(filters);
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};
